import { Suspense, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { itemDetail as itemDetailInit } from '../../actions/viewInitializationActions'
import _ from 'lodash'
import { cn } from '@appfluence/classnames'
import { useDispatch, useSelector, useStore } from 'react-redux'
import './ItemDetail.css'
import { QuadrantSelector } from '../../components/input/quadrant/QuadrantSelector'
import { Separator } from '../../components/basic/Separator'
import { DirectionalHint } from '@fluentui/react'
import { PeopleSection } from '../../components/item/PeopleSection'
import { ItemLinkAttribute } from '../../components/item/ItemLinkAttributte'
import { ChatComponent } from '../../components/item/ChatComponent'
import { itemHelper, reminderHelper, stateHelper, tagHelper, userHelper } from '../../common/src/helpers'
import { Loading } from '../../components/basic/Loading'
import { DateSection } from '../../components/basic/DateSection'
import {
  AlertModalContext,
  FileContext,
  ItemDetailContext,
  ItemLinkPanelContext,
  MoveItemModalContext,
} from '../../contexts'
import { KEY_CODE } from '../../common/src/helpers/keyboardHelper'
import { FileDropSection } from './FileDropSection'
import { FilesSectionContent } from './FilesSectionContent'
import { LinksSectionContent } from './LinksSectionContent'
import { IconDetail } from '../../components/icon/IconDetail'
import { ItemEffortCircle } from '../../components/item/ItemEffortCircle'
import { InboxIcon, MoveProjectButton, ProjectName, Section } from './ItemDetailSubcomponents'
import { useOnVariableChange } from '../../hooks/hooks'
import {
  dateToAllDay,
  dateToTimestampInSeconds,
  increaseHourToSecondsTimestamp,
  toDate,
} from '../../common/src/helpers/dateHelper'
import { PM_API_URLS } from '../../common/src/constants'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent, EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { queryParamsHelper } from '../../helpers'
import { RepeatSection } from '../../components/basic/RepeatSection'
import { ItemProgressCircle } from '../../components/item/ItemProgressCircle'
import { ItemDatesPanel } from '../../components/panels/item/ItemDatesPanel'
import { useCreateOnboardingStep, useSendStepEventIfNeeded } from '../../hooks/onboardingHooks'
import { OnboardingStepKeys } from '../../actions/onboardingActions'
import { OnboardingCoachmark } from '../../components/onboarding/OnboardingCoachmark'
import { FollowersPanel } from '../../components/item/FollowersPanel'
import { useRandomValueFromArray } from '../../hooks/arrayHooks'
import { useCreateLinkedItem, useItemParams, useLinkedProjectId } from '../../hooks/itemHooks'
import { useTranslation } from 'react-i18next'
import { IVIEW_PLACEHOLDER_TYPES } from '../../components/placeholder/IViewPlaceholderTypes'
import { useCreateItemModal } from '@/hooks/useCreateItemModal'
import {
  getItemIfNeeded,
  markItemAsModifiedInModel,
  sendPostReminder,
  sendReminder,
} from '../../common/src/actions/combinedAPI'
import { isMobile } from '../../common/src/helpers/platformHelper'
import { LazyIViewPlaceholder } from '../../components/placeholder/LazyIViewPlaceholder'
import { ItemReminderPanel } from '../../components/panels/item/ItemReminderPanel'
import { ReminderCircle } from '../../components/item/ReminderCircle'
import { AddItemButtonWithTooltip as AddButton } from '../../components/buttons/AddItemButtonWithTooltip'
import { HiddenFileInput } from '../../components/input/HiddenFileInput'
import { LINK_PANEL_ACTION_TYPES } from '../../components/panels/item/ItemLinkPanelSingleton'
import { useMe } from '../../common/src/hooks/usersHooks'
import { useItemConflicts } from '../../hooks/conflictHooks'
import { useBoolean } from '../../common/src/hooks/useBoolean'
import { ItemConflicts } from './ItemConflicts'
import { DependencyLinkModal } from '../../components/modal/DependencyLinkModal'
import { getLinks, updateLinks } from '../../common/src/actions/linksAPI'
import { TipTapNotes } from '../../components/notes/TipTapNotes/TipTapNotes'
import { useSelectedItemId } from '../../hooks/PMHooks'
import { NOTES_LIMIT_FOR_AI } from '../../hooks/aiHooks'
import { FillWithAIButton } from '../../components/buttons/FillWithAIButton'
import { addTag } from '../../common/src/actions/tagsAPI'
import { useConfig } from '../../queries/config'
import { ItemDetailOptionsButton } from '@/views/ItemDetail/ItemDetailOptionsButton'
import { ItemDetailShareButton } from '@/views/ItemDetail/ItemDetailShareButton'
import { useMutateItem } from '@/queries/items'
import {
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Tab,
  TabList,
  Textarea,
} from '@fluentui/react-components'
import {
  AddCircle,
  Attach,
  ChevronDown,
  ChevronUp,
  DocumentAdd,
  DocumentTextLink,
  GlobeAdd,
} from '@/components/BundledIcons'
import { FieldDetail } from '@/components/basic/FieldDetail'
import { StarredCell } from '@/components/starred/StarredCell'
import { TagDetails } from '@/components/tags/TagDetails'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { useRequestReview } from '@/hooks/useRequestReview'
import { hideItemDetailsAtom } from '@/atoms'
import { useAtom } from 'jotai'
import { ProjectDetailView } from '@/views/project/ProjectDetail.js'

const REQUIRED_INACTIVITY_PERIOD = 3000

const TO_REMINDER_FREQUENCY = Object.fromEntries(
  Object.entries(reminderHelper.REMINDER_FREQUENCY_TYPE).map(([key, value]) => [value, +key])
)

const useFollowersPanelController = () => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  return {
    isOpen: open,
    open: useCallback(
      mode => {
        dispatch(dispatchEvent(AMPLITUDE_ACTION_TYPES.OPEN_FOLLOWERS_PANEL, { mode }))
        setOpen(true)
      },
      [dispatch]
    ),
    dismiss: useCallback(() => setOpen(false), []),
  }
}

const createReminder = ({ [reminderHelper.KEYS.REMINDER_DATE]: date }, relatedItem) => {
  return date
    ? {
        item: _.template(PM_API_URLS.ITEM)({ id: itemHelper.getId(relatedItem) }),
        reminder_date: toDate(date),
        enabled: true,
      }
    : null
}

const PIVOT_KEYS = {
  CHAT: 'CHAT',
  NOTES: 'NOTES',
  RESOURCES: 'RESOURCES',
  PROJECT: 'PROJECT',
}

const ItemDetail = ({ readOnly = false, pivotItemArray = [], displayOptionsMenu = true, customPlaceholderElement }) => {
  const selectedItemID = useSelectedItemId()
  const itemInGlobalState = useSelector(state => stateHelper.getItem(state, selectedItemID))
  const globalItemProjectIdd = itemHelper.getProjectIdd(itemInGlobalState)
  const project = useSelector(state => stateHelper.getProject(state, globalItemProjectIdd))
  const itemModified = useSelector(state => stateHelper.isModifiedItem(state, selectedItemID))
  const store = useStore()
  const dispatch = useDispatch()
  const trackEvent = useCallback((...args) => dispatch(dispatchEvent(...args)), [dispatch])
  const { t } = useTranslation()
  const [, setUpdateManually] = useState(0)
  const config = useConfig().data
  const canUseAI = config?.ai && config?.ai_deployed
  const forceUpdate = useCallback(() => setUpdateManually(prev => prev + 1), [setUpdateManually])
  const { setProps: setAlertModalProps } = useContext(AlertModalContext)
  const createItemModal = useCreateItemModal()
  const platformIsMobile = isMobile()
  const { dispatch: addLinkDispatch } = useContext(ItemLinkPanelContext)
  const requestReview = useRequestReview()
  const linkedProjectId = useLinkedProjectId(itemInGlobalState)
  const linkedProject = useSelector(state => stateHelper.getProject(state, linkedProjectId))

  const _container = useRef(null)
  const forceScrollBottomInterval = useRef(null)
  const itemRef = useRef(null)
  const renderedId = useRef(null)

  const { uploadFiles } = useContext(FileContext)
  const fileInputRef = useRef(null)

  const moveItemModalContext = useContext(MoveItemModalContext)

  useEffect(() => {
    dispatch(itemDetailInit())
  }, [dispatch])

  const [showDatesPanel, setShowDatesPanel] = useState(false)
  const openDatesPanel = useCallback(() => {
    setShowDatesPanel(true)
  }, [setShowDatesPanel])
  const closeDatesPanel = useCallback(() => {
    setShowDatesPanel(false)
  }, [setShowDatesPanel])

  const [showReminderPanel, setShowReminderPanel] = useState(false)
  const openReminderPanel = useCallback(() => {
    setShowReminderPanel(true)
  }, [setShowReminderPanel])
  const closeReminderPanel = useCallback(() => {
    setShowReminderPanel(false)
  }, [setShowReminderPanel])

  const sendStepEventIfNeeded = useSendStepEventIfNeeded()
  const notesOnboardingStep = useCreateOnboardingStep(OnboardingStepKeys.ADD_NOTES_TO_ITEMS)
  const dueDateOnboardingStep = useCreateOnboardingStep(OnboardingStepKeys.ADD_DATES_TO_ITEMS)
  const assignOnboardingStep = useCreateOnboardingStep(OnboardingStepKeys.ASSIGN_ITEMS)

  //still need to improve this section to properly manage changes in items
  useOnVariableChange(() => {
    if (itemInGlobalState) {
      if (itemRef.current && itemRef.current.get('id') === itemInGlobalState.get('id') && itemModified) {
        const prevItem = itemRef.current
        // Update local item with global item
        itemRef.current = itemInGlobalState
        // Maintain local changes in notes and name to avoid bugs while writing
        itemRef.current = itemRef.current.set(itemHelper.KEYS.DESCRIPTION_TEXT, itemHelper.getNotes(prevItem))
        itemRef.current = itemRef.current.set(itemHelper.KEYS.NAME, itemHelper.getName(prevItem))
      } else {
        itemRef.current = itemInGlobalState
      }
    } else {
      itemRef.current = null
    }
  }, [itemInGlobalState])

  const item = itemRef.current
  const quadrant = itemHelper.getQuadrant(item)
  const files = itemHelper.getFiles(item)
  const links = useSelector(state => stateHelper.getAssociatedLinksForItem(state, selectedItemID))
  const dueDate = itemHelper.getDueDate(item)
  const effort = itemHelper.getEffort(item)
  const estimatedEffort = itemHelper.getEstimatedEffort(item)
  const startDate = itemHelper.getStartDate(item)
  const frequency = itemHelper.getFrequency(item)
  const untilDate = itemHelper.getUntilDate(item)
  const allDay = itemHelper.getAllDay(item)
  const macResourceURL = itemHelper.getMacResourceURL(item)
  const notes = itemHelper.getNotes(item)
  const isInbox = itemHelper.isInbox(item)
  const isPlus = stateHelper.isInboxPlusItem(store.getState(), item)
  const clickableProject = !(isInbox || isPlus)
  const reminder = itemHelper.getReminder(item)
  const reminderDate = reminderHelper.getReminderDate(reminder)
  const reminderFrequency = reminderHelper.getReminderFrequency(reminder) || reminderHelper.FREQUENCY_TYPE.ONCE
  const reminderAllFollowers = reminderHelper.getReminderAllFollowers(reminder)
  const reminderNotes = reminderHelper.getReminderNotes(reminder)
  const reminderEnabled = reminderHelper.getReminderEnabled(reminder)
  const me = useMe()
  const premiumLevel = userHelper.getPremiumLevel(me)
  const tags = itemHelper.getTags(item)
  const linkProjectId = useLinkedProjectId(item)

  const hasReadOnlyTag = tags?.some(tag => tagHelper.getName(tag) === tagHelper.READONLY_TAG_NAME)
  const notesReadOnly = readOnly || hasReadOnlyTag
  const nameReadOnly = notesReadOnly
  const isFromOutlookCalendarConnector = tags?.some(tag => tagHelper.getName(tag) === 'outlook_calendar_connector')
  const datesReadOnly = readOnly || isFromOutlookCalendarConnector || linkProjectId !== null

  const projectIdd = itemHelper.getProjectIdd(item)
  const followersPanelController = useFollowersPanelController()

  const createLinkedItem = useCreateLinkedItem(item)

  const hasNotes = notes && notes.length > 0
  const notesPlaceholder = readOnly ? t('item_detail.no_notes_placeholder') : t('item_detail.notes_placeholder')
  const filesCount = files ? files.size : 0
  const linksCount = links ? links.size : 0
  const resourcesCount = filesCount + linksCount || undefined // To not show 0
  const [hideItemDetails, setHideItemDetails] = useAtom(hideItemDetailsAtom)

  const { mutate: mutateItem } = useMutateItem(selectedItemID)

  const _changeProperty = useCallback(
    (itemId, update = true) => {
      // We send the updated item from Redux store
      const item = stateHelper.getItem(store.getState(), itemId)
      if (item) {
        if (update) {
          forceUpdate()
        }
        mutateItem(item)
      }
    },
    [store, mutateItem, forceUpdate]
  )

  // We debounce the changeProperty function instead of sendItem so that the new item is calculated
  // just before sending it, making it possible to change the newest version of the item
  const debouncedChangeProperty = useMemo(
    () => _.debounce(_changeProperty, REQUIRED_INACTIVITY_PERIOD, { trailing: true }),
    [_changeProperty]
  )

  const changeProperty = useCallback(
    (prop, value, update = true, force = false) => {
      // Always update the local state without debouncing
      if (itemRef.current) {
        itemRef.current = itemRef.current.set(prop, value)
        if (update) {
          forceUpdate()
        }
      }
      const finalItem = itemRef.current
      dispatch(markItemAsModifiedInModel(finalItem))
      const func = force ? _changeProperty : debouncedChangeProperty
      func(itemHelper.getId(itemRef.current), update)

      // Send onboarding step events
      if (prop === itemHelper.KEYS.DESCRIPTION_TEXT) {
        sendStepEventIfNeeded(OnboardingStepKeys.ADD_NOTES_TO_ITEMS)
      } else if (prop === itemHelper.KEYS.DUE_DATE || prop === itemHelper.KEYS.START_DATE) {
        sendStepEventIfNeeded(OnboardingStepKeys.ADD_DATES_TO_ITEMS)
      } else if (prop === itemHelper.KEYS.OWNER_USERNAME) {
        sendStepEventIfNeeded(OnboardingStepKeys.ASSIGN_ITEMS)
      }
    },
    [dispatch, _changeProperty, debouncedChangeProperty, forceUpdate, sendStepEventIfNeeded]
  )

  const changeReminderProperty = useCallback(
    (prop, value, update = true) => {
      const currentReminder = !_.isEmpty(reminder)
        ? reminder.set(prop, value)
        : createReminder({ [prop]: value }, itemRef.current)

      if (reminder) {
        //This simulates in view that the reminder has already changed, also makes notes not update all the time
        itemRef.current = itemRef.current.setIn([itemHelper.KEYS.REMINDER, prop], value)
        const finalItem = itemRef.current
        dispatch(markItemAsModifiedInModel(finalItem))
      }

      if (update) {
        forceUpdate()
      }

      if (!_.isEqual(currentReminder, reminder)) {
        !_.isEmpty(reminder) ? dispatch(sendReminder(currentReminder)) : dispatch(sendPostReminder(currentReminder))
      }
    },
    [reminder, forceUpdate, dispatch]
  )

  const itemParams = useItemParams(readOnly)

  useEffect(() => {
    if (selectedItemID && selectedItemID !== renderedId.current) {
      dispatch(getItemIfNeeded(selectedItemID, { returnpubliclink: 1, ...itemParams }))
      dispatch(getLinks(selectedItemID))
    }
    renderedId.current = selectedItemID

    return () => {
      if (selectedItemID) {
        debouncedChangeProperty.flush()
      }
    }
  }, [selectedItemID, dispatch, debouncedChangeProperty, itemParams])

  const onChangeQuadrant = useCallback(q => changeProperty(itemHelper.KEYS.QUADRANT, _.parseInt(q)), [changeProperty])
  const onChangeName = (ev, data) => changeProperty(itemHelper.KEYS.NAME, data.value)
  const onChangeCompletion = useCallback(
    value => {
      const integerValue = _.parseInt(value)
      if (integerValue === 100) {
        requestReview()
        trackEvent(AMPLITUDE_ACTION_TYPES.COMPLETE_ITEM)
      }
      changeProperty(itemHelper.KEYS.COMPLETION_PERCENTAGE, integerValue)
    },
    [changeProperty, requestReview, trackEvent]
  )
  const onChangeEffort = effort => {
    changeProperty(itemHelper.KEYS.EFFORT, effort)
    changeProperty(itemHelper.KEYS.ESTIMATED_EFFORT, itemHelper.DEFAULT_ESTIMATED_EFFORT[effort])
  }

  // We can't disable forceUpdate because disabling it makes notes update with last PUT response while typing
  const onChangeNotes = value => changeProperty(itemHelper.KEYS.DESCRIPTION_TEXT, value)
  const onChangeOwner = useCallback(text => changeProperty(itemHelper.KEYS.OWNER_USERNAME, text), [changeProperty])
  const onChangeIcon = iconName => changeProperty(itemHelper.KEYS.ICON, iconName)
  const onChangeLink = link => changeProperty(itemHelper.KEYS.MAC_RESOURCE_URL, link)

  const adjustAllDayDates = () => {
    const item = itemRef.current
    if (!item) {
      return
    }
    const checked = itemHelper.isAllDay(item)
    if (checked) {
      const currentDates = [itemHelper.getStartDate(item), itemHelper.getDueDate(item)]
      const [startDate, dueDate] = _.map(currentDates, d => {
        return d ? dateToTimestampInSeconds(dateToAllDay(d)) : d
      })
      changeProperty(itemHelper.KEYS.DUE_DATE, dueDate, false)
      changeProperty(itemHelper.KEYS.START_DATE, startDate, false)
    }
    forceUpdate()
  }

  const onChangeStartDate = value => {
    const startTimestamp = dateToTimestampInSeconds(value)
    const dueTimestamp = itemHelper.getDueTimestamp(item)
    if (dueTimestamp <= startTimestamp) {
      const newDueTimestamp = increaseHourToSecondsTimestamp(startTimestamp, 1)
      changeProperty(itemHelper.KEYS.DUE_DATE, newDueTimestamp, false)
    }
    changeProperty(itemHelper.KEYS.START_DATE, startTimestamp, false)
    adjustAllDayDates()
  }

  const onChangeDueDate = value => {
    const dueTimestamp = dateToTimestampInSeconds(value)
    const startTimestamp = itemHelper.getStartTimestamp(item)
    if (!dueTimestamp) {
      changeProperty(itemHelper.KEYS.START_DATE, undefined, false)
    } else if (startTimestamp >= dueTimestamp) {
      const newStartTimestamp = increaseHourToSecondsTimestamp(dueTimestamp, -1)
      changeProperty(itemHelper.KEYS.START_DATE, newStartTimestamp, false)
    }
    changeProperty(itemHelper.KEYS.DUE_DATE, dueTimestamp, false)
    adjustAllDayDates()
  }

  const onChangeReminderDate = value => {
    const reminderTimestamp = dateToTimestampInSeconds(value)
    changeReminderProperty(reminderHelper.KEYS.REMINDER_DATE, reminderTimestamp ?? 0, true)
    if (value) {
      changeReminderProperty(reminderHelper.KEYS.REMINDER_ENABLED, true)
    }
    adjustAllDayDates()
  }

  const onChangeReminderNotes = text => {
    if (reminderEnabled) {
      changeReminderProperty(reminderHelper.KEYS.REMINDER_NOTES, text, true)
    }
  }

  const onChangeReminderFrequency = value => {
    changeReminderProperty(reminderHelper.KEYS.REMINDER_FREQUENCY, TO_REMINDER_FREQUENCY[value], true)
  }

  const onChangeReminderEnabled = value => {
    changeReminderProperty(reminderHelper.KEYS.REMINDER_ENABLED, value, true)
  }

  const onChangeFrequency = value => changeProperty(itemHelper.KEYS.FREQUENCY, value, true)

  const onChangeUntilDate = value => {
    const untilTimestamp = dateToTimestampInSeconds(value)
    changeProperty(itemHelper.KEYS.UNTIL_DATE, untilTimestamp, true)
  }

  const onToggleAllDay = checked => {
    changeProperty(itemHelper.KEYS.ALL_DAY, checked, false)
    adjustAllDayDates()
  }

  const onToggleAllFollowers = checked => {
    changeReminderProperty(reminderHelper.KEYS.REMINDER_ALL_FOLLOWERS, checked, true)
  }

  const moveItem = useCallback(() => {
    trackEvent(AMPLITUDE_ACTION_TYPES.MOVE_ITEM)

    moveItemModalContext.setMoveItemModalState({
      isDisplayed: true,
      item: item,
      initProject: isInbox || isPlus ? null : project,
      initQuadrant: isInbox || isPlus ? null : quadrant,
    })
  }, [trackEvent, moveItemModalContext, item, isInbox, isPlus, project, quadrant])

  const alertToMoveInboxItemPlus = useCallback(() => {
    setAlertModalProps({
      open: true,
      title: t('item_detail.move_inbox_plus_alert_title'),
      subText: t('item_detail.move_inbox_plus_alert_subtitle', { name: itemHelper.getName(item) }),
      onPrimaryActionClick: moveItem,
      onYesAlwaysClick: null,
    })
  }, [setAlertModalProps, t, item, moveItem])

  const triggerMoveItem = useCallback(() => {
    if (isPlus) {
      alertToMoveInboxItemPlus()
    } else {
      moveItem()
    }
  }, [isPlus, alertToMoveInboxItemPlus, moveItem])

  const onKeyPressName = event => {
    if (event.keyCode === KEY_CODE.ENTER) {
      event.preventDefault()
      event.target.blur()
    }
  }

  const [itemConflictsRequired, { toggle: toggleSeeConflicts }] = useBoolean(false)
  const {
    conflicts,
    loading: conflictsLoading,
    error: conflictsError,
  } = useItemConflicts({
    itemId: selectedItemID,
    required: itemConflictsRequired,
  })

  const handleChangeFileInput = useCallback(
    async evt => {
      const fileList = evt.target.files
      const files = Array.from(fileList)
      if (_.isEmpty(files)) {
        return
      }
      await uploadFiles({ item, files })
    },
    [uploadFiles, item]
  )

  const handleAddFile = useCallback(() => {
    fileInputRef.current.click()
  }, [])

  const [selectedLink, setSelectedLink] = useState({})
  const [linksSectionLoading, setLinksSectionLoading] = useState(false)
  const [showLinkModalToEdit, setShowLinkModalToEdit] = useState(false)
  const linksItems = useMemo(() => links.toJSON(), [links])
  const onChangeLinkModal = useCallback(
    async link => {
      setLinksSectionLoading(true)
      const oldLinks = link.id ? _.filter(linksItems, l => l.id !== link.id) : [...linksItems]
      const newLinks = [...oldLinks, link]
      await dispatch(updateLinks(selectedItemID, newLinks))
      setLinksSectionLoading(false)
    },
    [dispatch, linksItems, selectedItemID]
  )

  // this method enforces scroll to bottom during a certain time.
  // (Useful when virtual keyboard is displayed on mobile)
  const scrollBottom = (delay = 1400) => {
    const INTERVAL_TIME = 200
    if (forceScrollBottomInterval.current) {
      clearInterval(forceScrollBottomInterval.current)
    }
    let sum = 0
    forceScrollBottomInterval.current = setInterval(() => {
      sum += INTERVAL_TIME
      if (sum >= delay) {
        clearInterval(forceScrollBottomInterval.current)
      }
      const element = _container.current
      if (!element) {
        return
      }
      element.scrollTop = element.scrollHeight
    }, INTERVAL_TIME)
  }

  const placeholders = useMemo(() => {
    return [
      {
        title: t('item_detail.placeholder_0.title'),
        message: t('item_detail.placeholder_0.message'),
        type: IVIEW_PLACEHOLDER_TYPES.SELECTION,
      },
      {
        title: t('item_detail.placeholder_1.title'),
        message: t('item_detail.placeholder_1.message'),
        type: IVIEW_PLACEHOLDER_TYPES.SELECTION,
      },
      {
        title: t('item_detail.placeholder_2.title'),
        message: t('item_detail.placeholder_2.message'),
        type: IVIEW_PLACEHOLDER_TYPES.SELECTION,
      },
    ]
  }, [t])

  const handleAddItem = () => {
    createItemModal({
      mode: EVENT_EXTRA.CREATE_ITEM.MODE.ITEM_PLACEHOLDER,
      displayProjectSelector: true,
      quadrant: 0,
      open: true,
    })
  }

  const handleAddLink = () => {
    addLinkDispatch({ type: LINK_PANEL_ACTION_TYPES.SHOW_ADD_LINK, payload: { itemId: selectedItemID } })
  }

  const [pivotSelectedKey, setPivotSelectedKey] = useState(
    readOnly ? PIVOT_KEYS.NOTES : linkedProject ? PIVOT_KEYS.PROJECT : PIVOT_KEYS.CHAT
  )
  useEffect(() => {
    if (!linkedProject && pivotSelectedKey === PIVOT_KEYS.PROJECT) {
      setPivotSelectedKey(PIVOT_KEYS.CHAT)
    }
  }, [linkedProject, pivotSelectedKey])

  const updateNotesAI = newNotes => {
    changeProperty(itemHelper.KEYS.DESCRIPTION_TEXT, newNotes)
    dispatch(addTag({ tagName: tagHelper.AI_TAG_NAME, object: item }))
  }

  const placeholder = useRandomValueFromArray(placeholders)

  if (!selectedItemID || !item || itemHelper.isDeleted(item)) {
    const def = (
      <Suspense fallback={<Loading />}>
        <LazyIViewPlaceholder {...placeholder}>
          <AddButton
            id={'itemPlaceholder_addItemButton'}
            key={'AddButton'}
            text={t('item.create_item')}
            onClick={handleAddItem}
          />
        </LazyIViewPlaceholder>
      </Suspense>
    )
    return customPlaceholderElement || def
  }

  const itemDetailProviderValue = { scrollBottom }

  const handleAddURL = () => {
    setShowLinkModalToEdit(true)
    setSelectedLink({})
  }

  const notesHeader = hasReadOnlyTag ? (
    <span className="mx-4 mt-4 italic text-pm-orange">{t('item_detail.notes.readonly_connector')}</span>
  ) : undefined

  return (
    <FileDropSection item={item} readOnly={readOnly}>
      <div className="flex h-full flex-col overflow-visible" ref={_container} id="itemDetailContainer">
        <ItemDetailContext.Provider value={itemDetailProviderValue}>
          <div>
            <Section>
              <div className="group relative mb-1 mt-3.5 flex items-center">
                {projectIdd > 0 ? (
                  <QuadrantSelector
                    size={24}
                    quadrant={quadrant}
                    project={project}
                    isItem={true}
                    readOnly={readOnly}
                    onChanged={onChangeQuadrant}
                    animate
                  />
                ) : (
                  <InboxIcon />
                )}
                <div className="ml-2 flex min-w-2.5 flex-1 items-center">
                  <ProjectName
                    disabled={!clickableProject}
                    id="itemDetail_projectName"
                    projectIdd={globalItemProjectIdd}
                  />
                  {!readOnly && <MoveProjectButton onClick={triggerMoveItem} />}
                </div>
                <ItemDetailShareButton item={item} />
                {displayOptionsMenu && (
                  <ItemDetailOptionsButton
                    item={item}
                    readOnly={readOnly}
                    changeProperty={changeProperty}
                    itemConflictsRequired={itemConflictsRequired}
                    toggleSeeConflicts={toggleSeeConflicts}
                  />
                )}
              </div>
              <div className="mt-2 flex">
                <div className="relative mr-2 flex flex-col items-start">
                  <StarredCell object={item} readOnly={readOnly} className="mx-auto w-6 text-2xl" />
                  {!hideItemDetails && <IconDetail item={item} onChange={onChangeIcon} readOnly={readOnly} />}
                </div>
                <Textarea
                  className={cn(
                    'mb-1 min-w-0 flex-1 !border-none !bg-transparent',
                    itemHelper.isCompleted(item) && 'line-through'
                  )}
                  textarea={{
                    className: cn(
                      '!text-xl/5 !p-0 !min-h-16',
                      hideItemDetails && '!min-h-5 !h-5 md:!min-h-11 md:!h-11'
                    ),
                  }}
                  id="itemDetail_itemName"
                  resize="none"
                  value={itemHelper.getName(item)}
                  onChange={onChangeName}
                  onKeyDown={onKeyPressName}
                  readOnly={nameReadOnly}
                />
              </div>
            </Section>
            <div className={hideItemDetails ? 'hidden' : undefined}>
              {itemConflictsRequired && (conflicts?.conflicts?.length > 0 || !premiumLevel) && (
                <>
                  <Separator className="px-4" />
                  <ItemConflicts
                    conflicts={conflicts}
                    iAmPremium={premiumLevel}
                    loading={conflictsLoading}
                    error={conflictsError}
                  />
                </>
              )}
              <Separator className="px-4" />
              <Section>
                <div className="flex flex-wrap gap-x-4 gap-y-2.5">
                  <FieldDetail name={t('item.owner')}>
                    <PeopleSection
                      item={item}
                      projectIdd={projectIdd}
                      addFollowerTooltip={t('item_detail.add_follower_tooltip')}
                      onChangeOwner={onChangeOwner}
                      readOnly={readOnly}
                      containerRef={assignOnboardingStep.ref}
                      openFollowersPanel={followersPanelController.open}
                    />
                    {assignOnboardingStep.isVisible && (
                      <OnboardingCoachmark
                        stepKey={assignOnboardingStep.key}
                        target={assignOnboardingStep.ref.current}
                        onDismiss={assignOnboardingStep.hideCoachmark}
                        positioningContainerProps={{ directionalHint: DirectionalHint.leftCenter }}
                      />
                    )}
                  </FieldDetail>
                  <FieldDetail name={t('item.progress.name')} className="items-center">
                    <ItemProgressCircle
                      value={itemHelper.getCompletionPercentage(item)}
                      onChange={onChangeCompletion}
                      readOnly={readOnly}
                      title={t('item.progress.name')}
                    />
                  </FieldDetail>
                  <FieldDetail name={t('item.effort.name')} className="items-center">
                    <Tooltip content={t('item_detail.effort_tooltip')} relationship="description">
                      <ItemEffortCircle
                        effort={effort}
                        estimatedEffort={estimatedEffort}
                        onChange={onChangeEffort}
                        readOnly={readOnly}
                      />
                    </Tooltip>
                  </FieldDetail>
                  <FieldDetail name={t('item.reminder.reminders')} className="items-center">
                    <Tooltip content={t('item_detail.reminder_tooltip')} relationship="description">
                      <ReminderCircle
                        date={reminderDate}
                        onClick={openReminderPanel}
                        readOnly={readOnly}
                        isRecurrent={reminderFrequency && reminderFrequency !== reminderHelper.FREQUENCY_TYPE.ONCE}
                        isEnabled={reminderEnabled}
                      />
                    </Tooltip>
                  </FieldDetail>
                  <FieldDetail name={t('item.dates')} contentStyle={{ alignItems: 'flex-start' }}>
                    <Tooltip
                      content={t(
                        linkProjectId
                          ? 'item_detail.dates_tooltip_linked_project'
                          : isFromOutlookCalendarConnector
                            ? 'item_detail.dates_tooltip_outlook_connector'
                            : 'item_detail.dates_tooltip'
                      )}
                      relationship="description"
                    >
                      <div>
                        <ItemDatesPanel
                          startDate={startDate}
                          dueDate={dueDate}
                          allDay={allDay}
                          onChangeStartDate={onChangeStartDate}
                          onChangeDueDate={onChangeDueDate}
                          onToggleAllDay={onToggleAllDay}
                          frequency={frequency}
                          untilDate={untilDate}
                          onChangeFrequency={onChangeFrequency}
                          onChangeUntilDate={onChangeUntilDate}
                          isOpen={showDatesPanel}
                          onDismiss={closeDatesPanel}
                        />
                        {dueDate && startDate && (
                          <DateSection
                            name={t('item.start')}
                            date={startDate}
                            allDay={allDay}
                            onClick={openDatesPanel}
                            readOnly={datesReadOnly}
                          />
                        )}
                        <DateSection
                          name={dueDate ? t('item.due') : undefined}
                          date={dueDate}
                          allDay={allDay}
                          checkDue={true}
                          onClick={openDatesPanel}
                          readOnly={datesReadOnly}
                          containerRef={dueDateOnboardingStep.ref}
                        />
                        {dueDateOnboardingStep.isVisible && (
                          <OnboardingCoachmark
                            stepKey={dueDateOnboardingStep.key}
                            target={dueDateOnboardingStep.ref.current}
                            onDismiss={dueDateOnboardingStep.hideCoachmark}
                            positioningContainerProps={{ directionalHint: DirectionalHint.leftCenter }}
                          />
                        )}
                        {dueDate && (
                          <RepeatSection
                            frequency={frequency}
                            untilDate={untilDate}
                            onClick={openDatesPanel}
                            readOnly={readOnly}
                          />
                        )}
                      </div>
                    </Tooltip>
                  </FieldDetail>
                  <ItemReminderPanel
                    reminderDate={reminderDate}
                    allFollowers={reminderAllFollowers}
                    onChangeReminderDate={onChangeReminderDate}
                    onToggleAllFollowers={onToggleAllFollowers}
                    reminderFrequency={reminderFrequency}
                    onChangeReminderFrequency={onChangeReminderFrequency}
                    isOpen={showReminderPanel}
                    onDismiss={closeReminderPanel}
                    reminderNotes={reminderNotes}
                    onChangeReminderNotes={onChangeReminderNotes}
                    reminderEnabled={reminderEnabled}
                    onChangeReminderEnabled={onChangeReminderEnabled}
                  />
                  <FieldDetail name={t('item.tags')}>
                    <Tooltip content={t('item_detail.tags_tooltip')} relationship="description">
                      <TagDetails
                        object={item}
                        readOnly={readOnly}
                        placeholderTagProps={{ id: 'itemDetail_addTagsPlaceholder' }}
                      />
                    </Tooltip>
                  </FieldDetail>
                  <Tooltip
                    content={t('item_detail.link_tooltip')}
                    relationship="description"
                    visible={macResourceURL ? false : undefined}
                  >
                    <FieldDetail
                      name={t('item.link')}
                      contentStyle={{ width: '100%' }}
                      styleLabel={{ display: 'none' }}
                      className={queryParamsHelper.isEmbeddedOnOutlook() && 'hidden'}
                    >
                      <ItemLinkAttribute link={macResourceURL} onChange={onChangeLink} readOnly={readOnly} />
                    </FieldDetail>
                  </Tooltip>
                </div>
              </Section>
              <Separator className="mt-[5px] px-4" />
            </div>
          </div>
          <Button
            className="!max-w-full !pb-0 md:!pt-0"
            icon={hideItemDetails ? <ChevronDown /> : <ChevronUp />}
            appearance="transparent"
            onClick={() => setHideItemDetails(prev => !prev)}
          />
          <div className="flex min-h-[300px] flex-1 flex-col" ref={notesOnboardingStep.ref}>
            <TabList
              selectedValue={pivotSelectedKey}
              onTabSelect={(event, data) => setPivotSelectedKey(data.value)}
              className="px-2"
              style={{ boxShadow: '0 19px 20px -21px black' }}
            >
              {!readOnly && <Tab value={PIVOT_KEYS.CHAT}>{t('item_detail.chat')}</Tab>}
              <Tab value={PIVOT_KEYS.NOTES} ref={notesOnboardingStep.ref} data-testid="ItemDetail_NotesTab">
                {hasNotes ? <span className="mr-1 font-bold">•</span> : null}
                {t('item.notes')}
              </Tab>
              <div className="relative">
                <Tab value={PIVOT_KEYS.RESOURCES} className="relative !pr-8">
                  {t('item_detail.resources')}
                  {!!resourcesCount && ` (${resourcesCount})`}
                </Tab>
                <Menu>
                  <MenuTrigger disableButtonEnhancement>
                    <Button
                      onClick={event => event.stopPropagation()}
                      appearance="transparent"
                      icon={<AddCircle />}
                      className={cn('absolute right-0 top-1/2 -translate-y-1/2', readOnly && '!hidden')}
                    />
                  </MenuTrigger>
                  <MenuPopover>
                    <MenuList>
                      <MenuItem icon={<Attach />} onClick={handleAddFile}>
                        {t('item.file.add_file')}
                      </MenuItem>
                      <MenuItem icon={<DocumentAdd />} onClick={createLinkedItem}>
                        {t('item.create_linked_item')}
                      </MenuItem>
                      <MenuItem icon={<DocumentTextLink />} onClick={handleAddLink}>
                        {t('links_section.add_item')}
                      </MenuItem>
                      <MenuItem icon={<GlobeAdd />} onClick={handleAddURL}>
                        {t('item_detail.add_url')}
                      </MenuItem>
                    </MenuList>
                  </MenuPopover>
                </Menu>
              </div>
              {linkedProject && <Tab value={PIVOT_KEYS.PROJECT}>{t('project.project')}</Tab>}
            </TabList>
            <div className="min-h-0 flex-1">
              <ChatComponent
                item={item}
                readOnly={readOnly}
                openFollowersPanel={followersPanelController.open}
                isChatTabOpen={pivotSelectedKey === PIVOT_KEYS.CHAT}
                className={pivotSelectedKey !== PIVOT_KEYS.CHAT ? 'hidden' : undefined}
              />
              {pivotSelectedKey === PIVOT_KEYS.NOTES && (
                <TipTapNotes
                  key={selectedItemID}
                  value={notes}
                  onUpdate={onChangeNotes}
                  placeholder={notesPlaceholder}
                  readOnly={notesReadOnly}
                  autofocus={platformIsMobile ? false : 'end'}
                  editorClassName="min-h-full box-border focus:outline-none pb-8"
                  toolbarClassName="sticky top-0 py-2 px-4 z-20 bg-pm-white"
                  containerClassName="min-h-full"
                  contentClassName="px-4 box-border"
                  additionalToolbarButtons={
                    !notesReadOnly && canUseAI && itemHelper.getNotes(item)?.length < NOTES_LIMIT_FOR_AI ? (
                      <FillWithAIButton
                        itemName={itemHelper.getName(item)}
                        itemCurrentNotes={itemHelper.getNotes(item)}
                        itemProjectIdd={projectIdd}
                        itemQuadrant={quadrant}
                        updateNotes={updateNotesAI}
                        eventTrackingMode={EVENT_EXTRA.AI_FILL_NOTES.MODE.ITEM_DETAIL}
                      />
                    ) : undefined
                  }
                  header={notesHeader}
                  data-testid="ItemDetail_NotesEditor"
                />
              )}
              {pivotSelectedKey === PIVOT_KEYS.RESOURCES && (
                <div className="px-4">
                  <FilesSectionContent item={item} readOnly={readOnly} onAddFile={handleAddFile} />
                  <LinksSectionContent
                    item={item}
                    readOnly={readOnly}
                    selectedLink={selectedLink}
                    setSelectedLink={setSelectedLink}
                    loading={linksSectionLoading}
                    setLoading={setLinksSectionLoading}
                    setShowLinkModalToEdit={setShowLinkModalToEdit}
                    linksItems={linksItems}
                  />
                </div>
              )}
              {pivotSelectedKey === PIVOT_KEYS.PROJECT && <ProjectDetailView project={linkedProject} />}
            </div>
            <HiddenFileInput id="file" ref={fileInputRef} multiple onChange={handleChangeFileInput} />
            <DependencyLinkModal
              isBlocking={true}
              isOpen={showLinkModalToEdit}
              onDismiss={() => setShowLinkModalToEdit(false)}
              previousLink={selectedLink}
              onChange={onChangeLinkModal}
            />
          </div>
          {notesOnboardingStep.isVisible && (
            <OnboardingCoachmark
              stepKey={notesOnboardingStep.key}
              target={notesOnboardingStep.ref.current}
              onDismiss={notesOnboardingStep.hideCoachmark}
              positioningContainerProps={{ directionalHint: DirectionalHint.leftCenter }}
            />
          )}
        </ItemDetailContext.Provider>
      </div>
      <FollowersPanel
        serverID={selectedItemID}
        item={item}
        project={project}
        isOpen={followersPanelController.isOpen}
        onDismiss={followersPanelController.dismiss}
      />
    </FileDropSection>
  )
}

export const ItemDetailView = ItemDetail
