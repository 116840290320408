import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fromJS } from 'immutable'
import styled from 'styled-components'
import { Label, PrimaryButton } from '@fluentui/react'
import { SimpleModal } from './SimpleModal'
import { ProjectsDropdown } from '../dropdown/ProjectsDropdown'
import { QuadrantsDropdown } from '../dropdown/QuadrantsDropdown'
import { getNewItemMovingItemTo } from '../../common/src/actions/combinedAPI'
import { AlertModal } from './AlertModal'
import { itemHelper, projectHelper } from '../../common/src/helpers'
import { uriDataCreator } from '../../common/src/helpers/URLHelper'
import { PM_API_RESOURCE_TYPE } from '../../common/src/constants'
import { linkSubject, SOURCES } from '../../reactions/linkSubject'
import { useMergeState } from '../../common/src/hooks/enhancedHooks'
import { useTranslation } from 'react-i18next'
import { hasTouchScreen } from '@/helpers/deviceDetectionHelper'
import { ProjectSelectorDrawer } from '@/views/modal/createItem/ProjectSelectorDrawer'

const SProjectsDropdown = styled(ProjectsDropdown)`
  margin-bottom: 12px;
`

const modalStyles = {
  title: {
    fontWeight: 'bold',
  },
}

const DEFAULT_QUADRANT = 3

export const MoveItemModal = ({ item, initProject, initQuadrant, onMoveItem, ...rest }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [state, setState] = useMergeState({
    project: null,
    quadrant: DEFAULT_QUADRANT,
    loading: false,
    errorMessage: null,
    movedItem: null,
    showAlertModal: false,
  })

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setState(prevState => {
      const project = initProject !== undefined ? initProject : prevState.project
      return {
        project,
        quadrant: initQuadrant ?? (project ? (prevState.quadrant ?? DEFAULT_QUADRANT) : null),
        loading: false,
        errorMessage: null,
        movedItem: null,
        showAlertModal: false,
      }
    })
  }, [item, initProject, initQuadrant])
  /* eslint-enable react-hooks/exhaustive-deps */
  const { onDismiss } = rest

  const onMoveItemByDefault = useCallback(item => {
    const itemID = itemHelper.getId(item)
    const urlData = uriDataCreator(1, PM_API_RESOURCE_TYPE.ITEM, itemID)
    linkSubject.next({ urlData, source: SOURCES.MOVE_ITEM_MODAL })
  }, [])

  const moveItem = async () => {
    if (!item) {
      return
    }
    const { project, quadrant } = state
    setState({ loading: true })
    const response = await dispatch(getNewItemMovingItemTo(item, project, quadrant))
    const { error, payload } = response
    const errorMessage = error ? payload.message : null
    const showAlertModal = !error
    const movedItem = !error ? fromJS(payload) : null

    setState({
      loading: false,
      showAlertModal,
      errorMessage,
      movedItem,
    })
  }

  const openMovedItem = () => {
    const { project, movedItem } = state
    onDismiss()

    if (onMoveItem) {
      onMoveItem(movedItem, project)
    } else {
      onMoveItemByDefault(movedItem)
    }
  }

  const onProjectChanged = useCallback(
    project => {
      setState(prevState => ({
        project,
        quadrant: project ? (prevState.quadrant ?? DEFAULT_QUADRANT) : null,
      }))
    },
    [setState]
  )

  const onQuadrantChanged = useCallback(
    quadrant => {
      setState({ quadrant })
    },
    [setState]
  )

  const hideAlertModal = useCallback(() => {
    setState({ showAlertModal: false })
  }, [setState])

  const doNotOpenCurrentItem = () => {
    onDismiss()
  }

  const { project, quadrant, loading, errorMessage, showAlertModal } = state
  const projectName = projectHelper.getName(project) ?? ''
  const alertTitleSuccess = t('modal.move_item_alert_title_success')
  const alertSubtitleSuccess = project
    ? t('modal.move_item_alert_subtitle_success', { projectName })
    : t('modal.move_item_alert_subtitle_success_inbox')
  const primaryButtonDisabled = (initProject === project && initQuadrant === quadrant) || loading
  const closeButtonDisabled = loading
  const button = (
    <PrimaryButton
      id="moveItemModal_moveButton"
      key={'moveItemModal_moveButton'}
      onClick={moveItem}
      disabled={primaryButtonDisabled}
    >
      {t('modal.move')}
    </PrimaryButton>
  )
  return (
    <SimpleModal
      title={t('modal.move_item_title')}
      loading={loading}
      errorMessage={errorMessage}
      buttons={button}
      closeButtonProps={{
        disabled: closeButtonDisabled,
        text: t('modal.no_thanks'),
      }}
      styles={modalStyles}
      {...rest}
    >
      {hasTouchScreen() ? (
        <>
          <Label>{t('project.project')}</Label>
          <ProjectSelectorDrawer
            onSelectProject={(evt, project) => onProjectChanged(project)}
            selectedProject={project}
            className="w-full"
          />
        </>
      ) : (
        <SProjectsDropdown
          id="moveItemModal_destinationProjectDropdown"
          label={t('project.project')}
          selectedProject={project}
          onProjectChanged={onProjectChanged}
          enableInbox
        />
      )}
      <QuadrantsDropdown
        id="moveItemModal_quadrantDropdown"
        label={t('project.quadrant')}
        project={project}
        quadrant={quadrant}
        onQuadrantChanged={onQuadrantChanged}
      />
      <AlertModal
        title={alertTitleSuccess}
        subText={alertSubtitleSuccess}
        open={showAlertModal}
        onDismiss={hideAlertModal}
        primaryActionText={t('modal.open')}
        defaultActionText={t('modal.not_now')}
        onPrimaryActionClick={openMovedItem}
        onDefaultActionClick={doNotOpenCurrentItem}
      />
    </SimpleModal>
  )
}
