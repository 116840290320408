import { memo } from 'react'
import { TopBarAddButton } from './TopBarAddButton'
import { ADD_ITEM_KEYS } from '../../constants/shortcutKeys'
import { useTranslation } from 'react-i18next'

const AddItemButtonWithTooltip_ = props => {
  const { t } = useTranslation()
  return <TopBarAddButton tooltipKeys={ADD_ITEM_KEYS} tooltipContent={t('item.create_item')} {...props} />
}
export const AddItemButtonWithTooltip = memo(AddItemButtonWithTooltip_)
