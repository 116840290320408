import { ItemsFiltersPanel } from './ItemsFiltersPanel'
import { ProjectListFiltersView } from './ProjectListFiltersView'

export const ProjectListFiltersPanel = ({ ...rest }) => {
  return (
    <ItemsFiltersPanel {...rest}>
      <ProjectListFiltersView />
    </ItemsFiltersPanel>
  )
}
