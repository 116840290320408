import { ItemsFiltersPanel } from './ItemsFiltersPanel'
import { ProjectKanbanFiltersView } from './ProjectKanbanFiltersView'

export const ProjectKanbanFiltersPanel = ({ ...rest }) => {
  return (
    <ItemsFiltersPanel {...rest}>
      <ProjectKanbanFiltersView />
    </ItemsFiltersPanel>
  )
}
