import { type Editor } from '@tiptap/react'
import { useTranslation } from 'react-i18next'
import { ShortcutTooltipContent } from '../../../tooltip/ShortcutTooltipHost'
import { META_KEY } from '../../shortcutHelp/ShortcutHelp'
import { cn } from '@appfluence/classnames'
import { type ReactNode, useCallback, useRef, useState } from 'react'
import { Button, Input, ToggleButton, ToolbarButton, Tooltip } from '@fluentui/react-components'
import { ArrowDown, ArrowUp, Search, TaskListLtr, TextBulletListLtr } from '@/components/BundledIcons'
import { isFindNotesHotKey, useHotkey } from '@/hooks/useHotkey'

export type TipTapToolbarProps = {
  editor: Editor | null
  className?: string
  additionalButtons?: ReactNode
  readOnly?: boolean
}

const noListener = () => false

export const TipTapToolbar = ({ editor, className, additionalButtons, readOnly = false }: TipTapToolbarProps) => {
  const { t } = useTranslation()
  const [showSearch, setShowSearch] = useState(false)
  const searchInputRef = useRef<HTMLInputElement>(null)

  const searchResultsLength = editor?.storage?.searchAndReplace?.results.length ?? 0
  const searchResultIndex = editor?.storage?.searchAndReplace?.resultIndex ?? -1

  const goToSearchResult = () => {
    if (!editor) return
    const { results, resultIndex } = editor.storage.searchAndReplace
    const position: Range = results[resultIndex]

    if (!position) return

    // @ts-ignore
    editor.commands.setTextSelection(position)

    const { node } = editor.view.domAtPos(editor.state.selection.anchor)
    node instanceof HTMLElement && node.scrollIntoView({ block: 'nearest' })
  }

  const startShowingSearch = useCallback(() => {
    setShowSearch(true)
    setTimeout(() => {
      searchInputRef.current?.focus()
      searchInputRef.current?.select()
    }, 0)
    editor?.commands.setSearchTerm(searchInputRef.current?.value.trim() ?? '')
  }, [editor?.commands])

  useHotkey(editor?.isFocused ? isFindNotesHotKey : noListener, startShowingSearch)

  return (
    <div className={cn('flex flex-col gap-1', className)}>
      <div className={cn('flex gap-1')}>
        {!readOnly && (
          <Tooltip
            content={
              <ShortcutTooltipContent
                tooltipKeys={[META_KEY, '⇧', '8']}
                label={t('item_detail.notes.toggle_bullet_list')}
              />
            }
            relationship="label"
          >
            <ToggleButton
              appearance="subtle"
              icon={<TextBulletListLtr />}
              checked={editor?.isActive('bulletList')}
              onClick={() => editor?.chain().focus().toggleBulletList().run()}
            />
          </Tooltip>
        )}
        {!readOnly && (
          <Tooltip
            content={
              <ShortcutTooltipContent
                tooltipKeys={[META_KEY, '⇧', '9']}
                label={t('item_detail.notes.toggle_task_list')}
              />
            }
            relationship="label"
          >
            <ToggleButton
              appearance="subtle"
              icon={<TaskListLtr />}
              checked={editor?.isActive('taskList')}
              onClick={() => editor?.chain().focus().toggleTaskList().run()}
            />
          </Tooltip>
        )}
        <Tooltip
          content={<ShortcutTooltipContent label={t('item_detail.notes.search')} tooltipKeys={[META_KEY, 'F']} />}
          relationship="label"
        >
          <ToggleButton
            appearance="subtle"
            icon={<Search />}
            checked={showSearch}
            onClick={() => {
              setShowSearch(value => {
                const showSearch = !value
                if (!showSearch) {
                  editor?.commands.setSearchTerm('')
                  editor?.commands.resetIndex()
                } else {
                  startShowingSearch()
                }
                return showSearch
              })
            }}
          />
        </Tooltip>
        {additionalButtons}
      </div>
      <div className={cn('hidden gap-1 pb-1', showSearch && 'flex')}>
        <Input
          ref={searchInputRef}
          placeholder={t('item_detail.notes.search_placeholder')}
          onChange={(event, data) => {
            editor?.commands.setSearchTerm(data.value.trim())
            if (data.value.trim() === '') {
              editor?.commands.resetIndex()
            }
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              editor?.commands.nextSearchResult()
              goToSearchResult()
            } else if (event.key === 'Escape') {
              setShowSearch(false)
              editor?.commands.setSearchTerm('')
              editor?.commands.resetIndex()
            }
          }}
          className="min-w-0 flex-1"
        />
        <Tooltip content={t('item_detail.notes.previous_result')} relationship="label">
          <ToolbarButton
            icon={<ArrowUp />}
            onClick={() => {
              editor?.commands.previousSearchResult()
              goToSearchResult()
            }}
          />
        </Tooltip>
        <Tooltip content={t('item_detail.notes.next_result')} relationship="label">
          <ToolbarButton
            icon={<ArrowDown />}
            onClick={() => {
              editor?.commands.nextSearchResult()
              goToSearchResult()
            }}
          />
        </Tooltip>
        <div className="flex w-8 flex-shrink-0 items-center">
          {searchResultsLength ? searchResultIndex + 1 : searchResultIndex} / {searchResultsLength}
        </div>
      </div>
      {!readOnly && (
        <div className={cn('hidden gap-1 pb-1', showSearch && 'flex')}>
          <Input
            placeholder={t('item_detail.notes.replace_placeholder')}
            onChange={(event, data) => {
              editor?.commands.setReplaceTerm(data.value.trim())
            }}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                editor?.commands.replace()
              } else if (event.key === 'Escape') {
                setShowSearch(false)
                editor?.commands.setSearchTerm('')
                editor?.commands.resetIndex()
              }
            }}
            className="min-w-0 flex-1"
          />
          <Button
            appearance="subtle"
            size="small"
            onClick={() => {
              editor?.commands.replace()
            }}
          >
            {t('item_detail.notes.replace')}
          </Button>
          <Button
            appearance="subtle"
            size="small"
            onClick={() => {
              editor?.commands.replaceAll()
            }}
          >
            {t('item_detail.notes.replace_all')}
          </Button>
        </div>
      )}
    </div>
  )
}
