import {
  AddCircleFilled,
  AddCircleRegular,
  AddFilled,
  AddRegular,
  AddSquareFilled,
  AddSquareRegular,
  AlertFilled,
  AlertRegular,
  ArchiveArrowBackFilled,
  ArchiveArrowBackRegular,
  ArchiveFilled,
  ArchiveRegular,
  ArrowClockwiseFilled,
  ArrowClockwiseRegular,
  ArrowDownloadFilled,
  ArrowDownloadRegular,
  ArrowLeftFilled,
  ArrowLeftRegular,
  ArrowRightFilled,
  ArrowRightRegular,
  ArrowSortFilled,
  ArrowSortRegular,
  ArrowUploadFilled,
  ArrowUploadRegular,
  AttachFilled,
  AttachRegular,
  BinRecycleFullFilled,
  BinRecycleFullRegular,
  BranchCompareFilled,
  BranchCompareRegular,
  CalculatorArrowClockwiseFilled,
  CalculatorArrowClockwiseRegular,
  CalculatorFilled,
  CalculatorRegular,
  CalendarAgendaFilled,
  CalendarAgendaRegular,
  CalendarLtrFilled,
  CalendarLtrRegular,
  CalendarTodayFilled,
  CalendarTodayRegular,
  CalendarWorkWeekFilled,
  CalendarWorkWeekRegular,
  ChatFilled,
  ChatRegular,
  CheckboxCheckedFilled,
  CheckboxCheckedRegular,
  CheckboxUncheckedFilled,
  CheckboxUncheckedRegular,
  ChevronDownFilled,
  ChevronDownRegular,
  ChevronUpFilled,
  ChevronUpRegular,
  ClipboardLinkFilled,
  ClipboardLinkRegular,
  CodeFilled,
  CodeRegular,
  CopyFilled,
  CopyRegular,
  DeleteFilled,
  DeleteRegular,
  DismissFilled,
  DismissRegular,
  DocumentAddFilled,
  DocumentAddRegular,
  DocumentMentionFilled,
  DocumentMentionRegular,
  DocumentOnePageLinkFilled,
  DocumentOnePageLinkRegular,
  DocumentSaveFilled,
  DocumentSaveRegular,
  DocumentTextLinkFilled,
  DocumentTextLinkRegular,
  EditFilled,
  EditRegular,
  EyeFilled,
  EyeOffFilled,
  EyeOffRegular,
  EyeRegular,
  FilterDismissFilled,
  FilterDismissRegular,
  FilterFilled,
  FilterRegular,
  FolderArrowRightFilled,
  FolderArrowRightRegular,
  FolderOpenFilled,
  FolderOpenRegular,
  GlobeAddFilled,
  GlobeAddRegular,
  GridFilled,
  GridRegular,
  HistoryFilled,
  HistoryRegular,
  ImageFilled,
  ImageRegular,
  InfoFilled,
  InfoRegular,
  LinkAddFilled,
  LinkAddRegular,
  LinkFilled,
  LinkMultipleFilled,
  LinkMultipleRegular,
  LinkPersonFilled,
  LinkPersonRegular,
  LinkRegular,
  MailAllReadFilled,
  MailAllReadRegular,
  MailFilled,
  MailRegular,
  MentionFilled,
  MentionRegular,
  MoreHorizontalFilled,
  MoreHorizontalRegular,
  MultiselectLtrFilled,
  MultiselectLtrRegular,
  OpenFilled,
  OpenFolderFilled,
  OpenFolderRegular,
  OpenRegular,
  OptionsFilled,
  OptionsRegular,
  PanelLeftContractFilled,
  PanelLeftContractRegular,
  PanelLeftExpandFilled,
  PanelLeftExpandRegular,
  PanelRightExpandFilled,
  PanelRightExpandRegular,
  PeopleAddFilled,
  PeopleAddRegular,
  PeopleFilled,
  PeopleLockFilled,
  PeopleLockRegular,
  PeopleRegular,
  PersonAddFilled,
  PersonAddRegular,
  PersonFilled,
  PersonRegular,
  PersonSubtractFilled,
  PersonSubtractRegular,
  PrintFilled,
  PrintRegular,
  SearchSettingsFilled,
  SearchSettingsRegular,
  SelectAllOffFilled,
  SelectAllOffRegular,
  SelectAllOnFilled,
  SelectAllOnRegular,
  SendFilled,
  SendRegular,
  ShareAndroidFilled,
  ShareAndroidRegular,
  ShareFilled,
  ShareIosFilled,
  ShareIosRegular,
  ShareRegular,
  SparkleFilled,
  SparkleRegular,
  StarFilled,
  StarOffFilled,
  StarOffRegular,
  StarRegular,
  TabDesktopFilled,
  TabDesktopRegular,
  TaskListLtrFilled,
  TaskListLtrRegular,
  WarningFilled,
  WarningRegular,
  WindowNewFilled,
  WindowNewRegular,
  bundleIcon,
  ArrowUpRightFilled,
  ArrowUpRightRegular,
  SaveFilled,
  SaveRegular,
  SearchRegular,
  SearchFilled,
  ChevronLeftFilled,
  ChevronLeftRegular,
  ChevronRightFilled,
  ChevronRightRegular,
  ArrowUpFilled,
  ArrowUpRegular,
  ArrowDownRegular,
  ArrowDownFilled,
  TextBulletListLtrFilled,
  TextBulletListLtrRegular,
  ArrowReplyFilled,
  ArrowReplyRegular,
} from '@fluentui/react-icons'

export const Add = bundleIcon(AddFilled, AddRegular)
export const AddCircle = bundleIcon(AddCircleFilled, AddCircleRegular)
export const AddSquare = bundleIcon(AddSquareFilled, AddSquareRegular)
export const Alert = bundleIcon(AlertFilled, AlertRegular)
export const Archive = bundleIcon(ArchiveFilled, ArchiveRegular)
export const ArchiveArrowBack = bundleIcon(ArchiveArrowBackFilled, ArchiveArrowBackRegular)
export const ArrowClockwise = bundleIcon(ArrowClockwiseFilled, ArrowClockwiseRegular)
export const ArrowDown = bundleIcon(ArrowDownFilled, ArrowDownRegular)
export const ArrowDownload = bundleIcon(ArrowDownloadFilled, ArrowDownloadRegular)
export const ArrowLeft = bundleIcon(ArrowLeftFilled, ArrowLeftRegular)
export const ArrowReply = bundleIcon(ArrowReplyFilled, ArrowReplyRegular)
export const ArrowRight = bundleIcon(ArrowRightFilled, ArrowRightRegular)
export const ArrowSort = bundleIcon(ArrowSortFilled, ArrowSortRegular)
export const ArrowUp = bundleIcon(ArrowUpFilled, ArrowUpRegular)
export const ArrowUpload = bundleIcon(ArrowUploadFilled, ArrowUploadRegular)
export const ArrowUpRight = bundleIcon(ArrowUpRightFilled, ArrowUpRightRegular)
export const Attach = bundleIcon(AttachFilled, AttachRegular)
export const BinRecycleFull = bundleIcon(BinRecycleFullFilled, BinRecycleFullRegular)
export const BranchCompare = bundleIcon(BranchCompareFilled, BranchCompareRegular)
export const Calculator = bundleIcon(CalculatorFilled, CalculatorRegular)
export const CalculatorArrowClockwise = bundleIcon(CalculatorArrowClockwiseFilled, CalculatorArrowClockwiseRegular)
export const CalendarAgenda = bundleIcon(CalendarAgendaFilled, CalendarAgendaRegular)
export const CalendarLtr = bundleIcon(CalendarLtrFilled, CalendarLtrRegular)
export const CalendarToday = bundleIcon(CalendarTodayFilled, CalendarTodayRegular)
export const CalendarWorkWeek = bundleIcon(CalendarWorkWeekFilled, CalendarWorkWeekRegular)
export const Chat = bundleIcon(ChatFilled, ChatRegular)
export const CheckboxChecked = bundleIcon(CheckboxCheckedFilled, CheckboxCheckedRegular)
export const CheckboxUnchecked = bundleIcon(CheckboxUncheckedFilled, CheckboxUncheckedRegular)
export const ChevronDown = bundleIcon(ChevronDownFilled, ChevronDownRegular)
export const ChevronLeft = bundleIcon(ChevronLeftFilled, ChevronLeftRegular)
export const ChevronRight = bundleIcon(ChevronRightFilled, ChevronRightRegular)
export const ChevronUp = bundleIcon(ChevronUpFilled, ChevronUpRegular)
export const ClipboardLink = bundleIcon(ClipboardLinkFilled, ClipboardLinkRegular)
export const Code = bundleIcon(CodeFilled, CodeRegular)
export const Copy = bundleIcon(CopyFilled, CopyRegular)
export const Delete = bundleIcon(DeleteFilled, DeleteRegular)
export const Dismiss = bundleIcon(DismissFilled, DismissRegular)
export const DocumentAdd = bundleIcon(DocumentAddFilled, DocumentAddRegular)
export const DocumentMention = bundleIcon(DocumentMentionFilled, DocumentMentionRegular)
export const DocumentOnePageLink = bundleIcon(DocumentOnePageLinkFilled, DocumentOnePageLinkRegular)
export const DocumentSave = bundleIcon(DocumentSaveFilled, DocumentSaveRegular)
export const DocumentTextLink = bundleIcon(DocumentTextLinkFilled, DocumentTextLinkRegular)
export const Edit = bundleIcon(EditFilled, EditRegular)
export const Eye = bundleIcon(EyeFilled, EyeRegular)
export const EyeOff = bundleIcon(EyeOffFilled, EyeOffRegular)
export const Filter = bundleIcon(FilterFilled, FilterRegular)
export const FilterDismiss = bundleIcon(FilterDismissFilled, FilterDismissRegular)
export const FolderArrowRight = bundleIcon(FolderArrowRightFilled, FolderArrowRightRegular)
export const FolderOpen = bundleIcon(FolderOpenFilled, FolderOpenRegular)
export const GlobeAdd = bundleIcon(GlobeAddFilled, GlobeAddRegular)
export const History = bundleIcon(HistoryFilled, HistoryRegular)
export const Image = bundleIcon(ImageFilled, ImageRegular)
export const Info = bundleIcon(InfoFilled, InfoRegular)
export const Link = bundleIcon(LinkFilled, LinkRegular)
export const LinkAdd = bundleIcon(LinkAddFilled, LinkAddRegular)
export const LinkMultiple = bundleIcon(LinkMultipleFilled, LinkMultipleRegular)
export const LinkPerson = bundleIcon(LinkPersonFilled, LinkPersonRegular)
export const Mail = bundleIcon(MailFilled, MailRegular)
export const MailAllRead = bundleIcon(MailAllReadFilled, MailAllReadRegular)
export const Mention = bundleIcon(MentionFilled, MentionRegular)
export const MoreHorizontal = bundleIcon(MoreHorizontalFilled, MoreHorizontalRegular)
export const MultiselectLtr = bundleIcon(MultiselectLtrFilled, MultiselectLtrRegular)
export const Open = bundleIcon(OpenFilled, OpenRegular)
export const OpenFolder = bundleIcon(OpenFolderFilled, OpenFolderRegular)
export const Options = bundleIcon(OptionsFilled, OptionsRegular)
export const PanelLeftContract = bundleIcon(PanelLeftContractFilled, PanelLeftContractRegular)
export const PanelLeftExpand = bundleIcon(PanelLeftExpandFilled, PanelLeftExpandRegular)
export const PanelRightExpand = bundleIcon(PanelRightExpandFilled, PanelRightExpandRegular)
export const Person = bundleIcon(PersonFilled, PersonRegular)
export const PersonAdd = bundleIcon(PersonAddFilled, PersonAddRegular)
export const PersonSubtract = bundleIcon(PersonSubtractFilled, PersonSubtractRegular)
export const People = bundleIcon(PeopleFilled, PeopleRegular)
export const PeopleAdd = bundleIcon(PeopleAddFilled, PeopleAddRegular)
export const PeopleLock = bundleIcon(PeopleLockFilled, PeopleLockRegular)
export const Print = bundleIcon(PrintFilled, PrintRegular)
export const SelectAllOff = bundleIcon(SelectAllOffFilled, SelectAllOffRegular)
export const SelectAllOn = bundleIcon(SelectAllOnFilled, SelectAllOnRegular)
export const Send = bundleIcon(SendFilled, SendRegular)
export const Share = bundleIcon(ShareFilled, ShareRegular)
export const ShareAndroid = bundleIcon(ShareAndroidFilled, ShareAndroidRegular)
export const ShareIos = bundleIcon(ShareIosFilled, ShareIosRegular)
export const Search = bundleIcon(SearchFilled, SearchRegular)
export const SearchSettings = bundleIcon(SearchSettingsFilled, SearchSettingsRegular)
export const Sparkle = bundleIcon(SparkleFilled, SparkleRegular)
export const Star = bundleIcon(StarFilled, StarRegular)
export const StarOff = bundleIcon(StarOffFilled, StarOffRegular)
export const TabDesktop = bundleIcon(TabDesktopFilled, TabDesktopRegular)
export const TaskListLtr = bundleIcon(TaskListLtrFilled, TaskListLtrRegular)
export const TextBulletListLtr = bundleIcon(TextBulletListLtrFilled, TextBulletListLtrRegular)
export const Warning = bundleIcon(WarningFilled, WarningRegular)
export const WindowNew = bundleIcon(WindowNewFilled, WindowNewRegular)
export const Grid = bundleIcon(GridFilled, GridRegular)
export const Save = bundleIcon(SaveFilled, SaveRegular)
