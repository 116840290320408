import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { META_KEY, ShortcutHelp, isMac } from '../shortcutHelp/ShortcutHelp'
import { Fragment } from 'react'
import { isElectronApp } from '@/integrations/electron'

export type KeyboardShortcutListDialogProps = {
  open: boolean
  onClose: () => void
}

const ALT_KEY = isMac() ? '⌥' : 'Alt'
const SHIFT_KEY = isMac() ? '⇧' : 'Shift'

const SHORTCUTS = [
  {
    id: 'find',
    keys: [META_KEY, 'K'],
  },
  {
    id: 'find_notes',
    keys: [META_KEY, 'F'],
  },
  {
    id: 'print',
    keys: [META_KEY, 'P'],
  },
  {
    id: 'new_item',
    keys: [META_KEY, 'I'],
  },
  {
    id: 'start_multiselection',
    keys: [META_KEY, 'Item Click'],
  },
  {
    id: 'select_all',
    keys: [META_KEY, 'A'],
  },
  {
    id: 'end_multiselection',
    keys: ['Esc'],
  },
  {
    id: 'edit_last_message',
    keys: ['↑'],
  },
  {
    id: 'exit_edit_mode',
    keys: ['Esc'],
  },
  {
    id: 'focus_chat_input',
    keys: [ALT_KEY, 'R'],
  },
  {
    id: 'toggle_item_completion',
    keys: [ALT_KEY, 'D'],
  },
  {
    id: 'focus_quick_project_search',
    keys: [ALT_KEY, 'P'],
  },
  {
    id: 'open_filters_panel',
    keys: [ALT_KEY, 'F'],
  },
  {
    id: 'go_to_home',
    keys: ['H'],
  },
  ...(isElectronApp()
    ? [
        {
          id: 'zoom_in',
          keys: isMac() ? [META_KEY, '+'] : [META_KEY, SHIFT_KEY, '+'],
        },
        {
          id: 'zoom_out',
          keys: isMac() ? [META_KEY, '-'] : [META_KEY, SHIFT_KEY, '-'],
        },
      ]
    : []),
]

export const KeyboardShortcutListDialog = ({ open, onClose }: KeyboardShortcutListDialogProps) => {
  const { t } = useTranslation()
  return (
    <Dialog
      open={open}
      onOpenChange={(event, data) => {
        if (!data.open) onClose()
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{t('keyboard_shortcuts.dialog_title')}</DialogTitle>
          <DialogContent>
            <div className="mx-auto grid w-fit grid-cols-[max-content,1fr] gap-x-2 gap-y-3">
              {SHORTCUTS.map(shortcut => (
                <Fragment key={shortcut.id}>
                  <ShortcutHelp keys={shortcut.keys} className="justify-self-end" />
                  <span className="text-sm/6">{t(`keyboard_shortcuts.${shortcut.id}`)}</span>
                </Fragment>
              ))}
            </div>
          </DialogContent>
          <DialogActions>
            <DialogTrigger>
              <Button>{t('general.close')}</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  )
}
