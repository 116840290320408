import { forwardRef, useMemo, isValidElement, useState, useEffect } from 'react'
import { Virtuoso } from 'react-virtuoso'
import { ItemCell } from '@/components/itemCell/ItemCell'
import { Draggable } from '@hello-pangea/dnd'
import { GhostItemCell } from '../../itemCell/GhostItemCell'
import { itemHelper } from '@/common/src/helpers'
import { cn } from '@/modules/classnames'

export const BasicItemList = forwardRef(
  (
    {
      items,
      onClickItem,
      onCheckboxChange,
      selectedItemId,
      readOnly = false,
      draggable: draggableProp = false,
      narrow = false,
      placeholder,
      itemCellStyles,
      loading = false,
      shouldShowProject,
      showOwner = true,
      scrollerRef,
      ...rest
    },
    ref
  ) => {
    const draggable = !readOnly && draggableProp
    const itemToCell = (idx, item) => {
      if (isValidElement(item)) return item
      if (!item || !item.get) {
        return <GhostItemCell key={idx} />
      }
      const id = item.get('id')
      const selected = id === selectedItemId
      if (draggable) {
        const draggableId = id.toString() // it needs to be a string
        return (
          <Draggable draggableId={draggableId} index={idx} key={draggableId}>
            {(provided, snapshot) => (
              <ItemCell
                allItemsInList={items}
                shouldShowProject={shouldShowProject}
                item={item}
                idx={idx}
                onClick={onClickItem}
                styles={itemCellStyles}
                onCheckboxChange={onCheckboxChange}
                selected={selected}
                narrow={narrow}
                readOnly={readOnly}
                innerRef={provided.innerRef}
                isDragging={snapshot.isDragging}
                showOwner={showOwner}
                {...provided.dragHandleProps}
                {...provided.draggableProps}
              />
            )}
          </Draggable>
        )
      }
      return (
        <ItemCell
          item={item}
          idx={idx}
          onClick={onClickItem}
          styles={itemCellStyles}
          onCheckboxChange={onCheckboxChange}
          selected={selected}
          narrow={narrow}
          readOnly={readOnly}
          allItemsInList={items}
          shouldShowProject={shouldShowProject}
          showOwner={showOwner}
        />
      )
    }
    // disabling getItemCountForPage and getPageHeight since they're not working properly...
    const itemsForList = useMemo(() => {
      const res = [...items]
      if (loading) {
        res.push(...Array(25).fill(null))
      }
      if (placeholder) {
        res.push(placeholder)
      }
      return res
    }, [items, loading, placeholder])
    return (
      <Virtuoso
        data={itemsForList}
        itemContent={itemToCell}
        components={{ Item: HeightPreservingItem }}
        ref={ref}
        scrollerRef={scrollerRef}
        computeItemKey={(idx, item) => itemHelper.getId(item) ?? `idx-${idx}`}
        {...rest}
        className={cn('overflow-x-hidden', rest.className)}
      />
    )
  }
)

const HeightPreservingItem = ({ children, item, ...props }) => {
  const [size, setSize] = useState(0)
  const knownSize = props['data-known-size']
  useEffect(() => {
    setSize(prevSize => {
      return knownSize === 0 ? prevSize : knownSize
    })
  }, [knownSize])
  return (
    <div
      {...props}
      className="empty:box-border empty:min-h-[calc(var(--child-height))]"
      style={{
        '--child-height': `${size}px`,
      }}
    >
      {children}
    </div>
  )
}
