import styled from 'styled-components'
import { forwardRef, useState } from 'react'
import { cn } from '@appfluence/classnames'
import { useMobile } from '../helpers/responsiveHelpers'
import { ShortcutHelp } from './shortcutHelp/ShortcutHelp'
import { SEARCH_KEYS } from '../constants/shortcutKeys'
import { SearchBox } from '@fluentui/react-components'

const SShortcutHelp = styled(ShortcutHelp)`
  position: absolute;
  top: 4px;
  right: 4px;
  transition: opacity 0.2s ease;
  box-shadow: -8px 0 5px 0 ${p => p.theme.palette.white};
`

export const SearchBoxWithShortcut = forwardRef(({ className, onClear, ...rest }, ref) => {
  const [showHelp, setShowHelp] = useState(true)
  const helpClassName = cn('opacity-0', showHelp && 'opacity-100', 'group-hover:opacity-0')
  const isMobile = useMobile()
  const handleFocus = event => {
    rest?.onFocus?.(event)
    setShowHelp(false)
  }
  const handleBlur = event => {
    rest?.onBlur?.(event)
    setShowHelp(true)
  }
  return (
    <div className={cn('group relative w-48', className)}>
      <SearchBox
        {...rest}
        className="w-full"
        ref={ref}
        onFocus={handleFocus}
        onBlur={handleBlur}
        dismiss={{ onClick: onClear }}
      />
      {!isMobile && <SShortcutHelp className={helpClassName} keys={SEARCH_KEYS} />}
    </div>
  )
})
