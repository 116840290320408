import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { itemHelper } from '../../common/src/helpers'
import { updateCommentNotSend } from '../../actions/uiActions'
import { ChatFooter } from './ChatFooter'
import { FileContext } from '../../contexts'
import { getSafeComment } from '../../helpers/commentHelper'
import { AMPLITUDE_ACTION_TYPES, dispatchEvent as trackEvent } from '../../common/src/eventTracking/amplitudeEvents'
import { getCommentNotSend } from '../../selectors/uiSelectors'
import { Chat } from '../chat/Chat'
import { useCommentsInItem, useEditComment, useSendComment } from '../../queries/comments'
import { Button } from '@fluentui/react-components'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { Dismiss } from '../BundledIcons'
import { flushSync } from 'react-dom'
import { ArrowReply24Filled, Edit24Filled } from '@fluentui/react-icons'
import { useTranslation } from 'react-i18next'
import { useIsEditableComment } from '../chat/utils'
import { cn } from '@/modules/classnames'

const useElementEventListener = (element, event, handler) => {
  useEffect(() => {
    const _element = _.isFunction(element) ? element() : element
    _element?.addEventListener?.(event, handler)
    return () => {
      _element?.removeEventListener?.(event, handler)
    }
  }, [element, event, handler])
}

// Disable read confirmations on window blur. Disable and send read confirmations on focus.
const useManageWindowFocus = () => {
  const [windowFocused, setWindowFocused] = useState(true)

  const handleFocus = useCallback(() => {
    setWindowFocused(true)
  }, [])

  const handleBlur = useCallback(() => {
    setWindowFocused(false)
  }, [])

  useElementEventListener(window, 'focus', handleFocus)
  useElementEventListener(window, 'blur', handleBlur)

  return windowFocused
}

export const ChatComponent = ({ item, readOnly = false, openFollowersPanel, isChatTabOpen = true, className = '' }) => {
  const { t } = useTranslation()
  const windowFocused = useManageWindowFocus()
  const serverID = itemHelper.getId(item)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const textFieldRef = useRef(null)
  const commentRef = useRef('')
  const itemIdRef = useRef(serverID)
  const editingCommentIdRef = useRef(null)
  const newCommentEditedRef = useRef(null)
  const { uploadFiles } = useContext(FileContext)
  const comment = useSelector(state => getCommentNotSend(state, serverID)) ?? ''
  const { mutate: _sendComment } = useSendComment()
  const { mutate: editComment, variables: editCommentVariables, status: editCommentStatus } = useEditComment()
  const [editCommentId, setEditCommentId] = useState(null)
  const [replyToCommentId, setReplyToCommentId] = useState(null)
  const replyToCommentIdRef = useRef(null)
  const [newCommentEdited, setNewCommentEdited] = useState(null)
  const isVisible = windowFocused && isChatTabOpen
  const { data: commentsData } = useCommentsInItem(serverID, {
    enabled: isVisible,
  })
  const isEditableComment = useIsEditableComment()

  const replyToComment = useMemo(
    () =>
      commentsData?.pages
        .flatMap(page => page.objects)
        .flat()
        .find(comment => comment.id === replyToCommentId),
    [commentsData?.pages, replyToCommentId]
  )

  const commentBeingEditedText = useMemo(() => {
    if (!editCommentId) return null
    const commentBeingEdited = commentsData?.pages
      .flatMap(page => page.objects)
      .find(comment => comment.id === editCommentId)
    return commentBeingEdited?.original_text ?? commentBeingEdited?.text
  }, [commentsData?.pages, editCommentId])

  // Keep reference to comment
  useEffect(() => {
    commentRef.current = comment
  }, [comment])

  useEffect(() => {
    newCommentEditedRef.current = newCommentEdited
  }, [newCommentEdited])

  useEffect(() => {
    itemIdRef.current = serverID
    setEditCommentId(null)
    setNewCommentEdited(null)
    setReplyToCommentId(null)
  }, [serverID])

  useEffect(() => {
    editingCommentIdRef.current = editCommentId
  }, [editCommentId])

  useEffect(() => {
    replyToCommentIdRef.current = replyToCommentId
  }, [replyToCommentId])

  // TextField
  const onChangeComment = useCallback(
    (ev, text) => {
      if (editCommentId) {
        setNewCommentEdited(text)
      } else {
        dispatch(updateCommentNotSend(text, serverID))
      }
    },
    [dispatch, editCommentId, serverID]
  )

  // Actions
  // "Editable" only updates when some own props change so
  // these callbacks need to use refs.
  const handleEditComment = useCallback(() => {
    const safeComment = getSafeComment(newCommentEditedRef.current)
    if (!safeComment) return
    editComment({ commentId: editingCommentIdRef.current, text: safeComment })
    setEditCommentId(null)
    setNewCommentEdited(null)
  }, [editComment])

  const sendComment = useCallback(
    event => {
      event.preventDefault()
      if (editingCommentIdRef.current) {
        handleEditComment()
        return
      }
      const safeComment = getSafeComment(commentRef.current)
      if (itemIdRef.current && !_.isEmpty(safeComment)) {
        _sendComment({
          commentText: safeComment,
          itemId: itemIdRef.current,
          replyToCommentId: replyToCommentIdRef.current,
        })
        dispatch(updateCommentNotSend('', itemIdRef.current))
        dispatch(trackEvent(AMPLITUDE_ACTION_TYPES.SEND_COMMENT))
        setReplyToCommentId(null)
      }
    },
    [_sendComment, dispatch, handleEditComment]
  )

  const focusContentEditable = useCallback(() => {
    textFieldRef.current?.focus()
    // move focus to end of contenteditable
    window.getSelection().selectAllChildren(textFieldRef.current)
    window.getSelection().collapseToEnd()
  }, [])

  const startEditingComment = useCallback(
    (commentId, startText) => {
      flushSync(() => {
        setEditCommentId(commentId)
        setNewCommentEdited(startText)
      })
      focusContentEditable()
    },
    [focusContentEditable]
  )

  const handleFilesToUpload = useCallback(
    async files => {
      setLoading(true)
      await uploadFiles({ item, files })
      setLoading(false)
    },
    [uploadFiles, item]
  )

  const cancelEdit = useCallback(() => {
    flushSync(() => {
      setEditCommentId(null)
      setNewCommentEdited(null)
    })
    focusContentEditable()
  }, [focusContentEditable])

  const cancelReply = useCallback(() => {
    flushSync(() => {
      setReplyToCommentId(null)
    })
    focusContentEditable()
  }, [focusContentEditable])

  const startEditingLastComment = useCallback(() => {
    const now = new Date()
    const lastComment = commentsData?.pages
      .flatMap(page => page.objects)
      .find(comment => isEditableComment(comment, now))
    if (!lastComment) return
    startEditingComment(lastComment.id, lastComment.raw ?? lastComment.text)
  }, [commentsData?.pages, isEditableComment, startEditingComment])

  const startReplyingToComment = useCallback(
    commentId => {
      setReplyToCommentId(commentId)
      focusContentEditable()
    },
    [focusContentEditable]
  )

  return (
    <div className={cn('flex h-full flex-col', className)}>
      <Chat
        itemId={serverID}
        isVisible={isVisible}
        className="min-h-0 flex-1"
        startEditingComment={startEditingComment}
        replyToComment={startReplyingToComment}
        optimisticEditCommentId={editCommentVariables?.commentId}
        optimisticEditCommentText={editCommentVariables?.text}
        optimisticEditCommentStatus={editCommentStatus}
        itemFollowers={itemHelper.getFollowers(item, false)}
      />
      {!!editCommentId && (
        <div className="flex h-10 gap-2 border-0 border-t border-solid border-t-pm-black bg-neutral-100 px-4 py-1 dark:bg-neutral-900">
          <div className="ml-1 flex items-center justify-center">
            <Edit24Filled />
          </div>
          <div className="ml-4 flex h-full min-w-0 flex-1 flex-col justify-center">
            <span className="truncate text-xs">{t('item_chat.edit_box_title')}</span>
            <span className="truncate text-neutral-700 dark:text-neutral-400">{commentBeingEditedText}</span>
          </div>
          <Tooltip content={t('item_chat.cancel_edit_button_tooltip')} relationship="label">
            <Button appearance="transparent" icon={<Dismiss />} onClick={cancelEdit} />
          </Tooltip>
        </div>
      )}
      {!!replyToCommentId && (
        <div className="flex h-10 gap-2 border-0 border-t border-solid border-t-pm-black bg-neutral-100 px-4 py-1 dark:bg-neutral-900">
          <div className="ml-1 flex items-center justify-center">
            <ArrowReply24Filled />
          </div>
          <div className="ml-4 flex h-full min-w-0 flex-1 flex-col justify-center">
            <span className="truncate text-xs">
              {t('item_chat.reply_to', { name: replyToComment?.author_fullname })}
            </span>
            <span className="truncate text-neutral-700 dark:text-neutral-400">
              {replyToComment?.original_text ?? replyToComment?.raw}
            </span>
          </div>
          <Tooltip content={t('item_chat.cancel_reply_button_tooltip')} relationship="label">
            <Button appearance="transparent" icon={<Dismiss />} onClick={cancelReply} />
          </Tooltip>
        </div>
      )}
      {!readOnly && (
        <ChatFooter
          comment={editCommentId ? newCommentEdited : comment}
          onChangeComment={onChangeComment}
          sendComment={sendComment}
          onFilesToUpload={handleFilesToUpload}
          loading={loading}
          openFollowersPanel={openFollowersPanel}
          itemID={serverID}
          isCurrentlyVisible={isVisible}
          textFieldRef={textFieldRef}
          focusContentEditable={focusContentEditable}
          isEditModeOn={!!editCommentId}
          cancelEdit={cancelEdit}
          startEditingLastComment={startEditingLastComment}
        />
      )}
    </div>
  )
}
