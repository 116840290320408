import React, { memo } from 'react'
import styled from 'styled-components'
import { Text } from '../text/Text'
import { AppfluenceTitle } from '../title/AppfluenceTitle'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { FlexRow } from './FlexContainer'
import { useConfig } from '../../queries/config'

const HeaderContainer = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;

  width: 100%;
  box-sizing: border-box;
  padding: ${p => (p.narrow ? '12px' : '12px 24px')};
  box-shadow: var(--shadow4);
`

const HeaderText = styled(Text).attrs({
  variant: 'large',
  block: true,
})`
  font-weight: 600;
  margin-left: 12px;
  padding-left: 12px;
  box-sizing: border-box;
  border-left: 2px solid ${p => p.theme.palette.neutralLight};
  color: ${p => p.theme.palette.black};
`

const SideContainer = styled(FlexRow)`
  align-items: center;
`

export const MainHeader = memo(({ title, onClickLogo, children }) => {
  const narrow = useNarrowWidth()
  const appfluenceName = useConfig().data?.app_display_name
  return (
    <HeaderContainer narrow={narrow}>
      <SideContainer>
        <AppfluenceTitle title={appfluenceName} onClick={onClickLogo} />
        {title && <HeaderText title={window.location.href}>{title}</HeaderText>}
      </SideContainer>
      <SideContainer>{children}</SideContainer>
    </HeaderContainer>
  )
})
