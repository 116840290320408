import styled from 'styled-components'
import { memo, useCallback, useRef } from 'react'
import { Button } from '@fluentui/react-components'
import { cn } from '@appfluence/classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useBoolean } from '../../common/src/hooks/useBoolean'
import { useMobile } from '../../helpers/responsiveHelpers'
import { TopRightBar } from '../../components/layout/TopRightBar'
import { FilterButton } from '../../components/buttons/FilterButton'
import { setDefaultFilterProjects, setProjectsTextFilter } from '../../common/src/actions/filtersActions'
import { getProjectFiltersCount, getProjectsTextFilter } from '../../common/src/selectors/filtersSelectors'
import { ProjectsFiltersPanel } from '../filters/ProjectsFiltersPanel'
import { TopBarAddButton } from '../../components/buttons/TopBarAddButton'
import { useSearchKeyPress } from '../../hooks/useSearchKeyPress'
import { useTranslation } from 'react-i18next'
import { useShowCreateProjectView } from '../../hooks/projectHooks'
import { EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { useFilterTagsFromParams } from '../../hooks/filterHooks'
import { stateHelper } from '../../common/src/helpers'
import { SearchBoxWithShortcut } from '../../components/SearchBoxWithShortcut'
import { ShortcutTooltipContent } from '../../tooltip/ShortcutTooltipHost'
import { SEARCH_KEYS } from '../../constants/shortcutKeys'
import { useTextSearch } from '../../hooks/useTextSearch'
import { Grid, TaskListLtr } from '@/components/BundledIcons'
import { setProjectsDisplayMode } from '@/actions/uiActions'
import { Tooltip } from '@/components/tooltip/Tooltip'
import { Search16Regular } from '@fluentui/react-icons'

const MARGIN_SIDE = 6
const PROJECTS_TO_SHOW_SEARCH_MOBILE = 6

const StyledFilterButton = styled(FilterButton).attrs({
  size: 16,
})`
  margin: 0 ${MARGIN_SIDE}px;
`

export const ProjectsListTopBar = memo(({ mode }) => {
  const isGridModeEnabled = mode === 'Grid'
  const dispatch = useDispatch()
  const filtersCount = useSelector(getProjectFiltersCount)
  const setTextFilter = (...args) => dispatch(setProjectsTextFilter(...args))
  const textFilter = useSelector(getProjectsTextFilter)
  const isMobilePlatform = useMobile()
  const [isFPanelOpen, { setTrue: showFPanel, setFalse: hideFPanel }] = useBoolean(false)
  const [isSearchBoxShown, { setTrue: showSearchBox, setFalse: hideSearchBox }] = useBoolean(!isMobilePlatform)
  const searchBoxRef = useRef(null)
  const { t } = useTranslation()
  const projectsCount = useSelector(state => stateHelper.getAllProjects(state).size)
  const { textSearch, onSearchBoxChange, onSearch } = useTextSearch({ setTextFilter, globalTextSearch: textFilter })

  const onClickSearch = useCallback(() => {
    showSearchBox()
    setTimeout(() => {
      searchBoxRef.current.focus()
    }, 100)
  }, [showSearchBox])
  useSearchKeyPress(onClickSearch)

  const onCreateProject = useShowCreateProjectView(EVENT_EXTRA.CREATE_PROJECT.MODE.PROJECT_LIST_TOP_BAR)

  const filterButtonTooltip = t('project_top_bar.filter_button_tooltip')
  const searchButtonTooltip = t('project_top_bar.search_button_tooltip')

  const shouldSearchBeShown = !isMobilePlatform || projectsCount > PROJECTS_TO_SHOW_SEARCH_MOBILE

  const filterButtonRef = useRef(null)

  useFilterTagsFromParams(true)

  const onClickGridToggle = () => {
    const nextMode = isGridModeEnabled ? 'List' : 'Grid'
    dispatch(setProjectsDisplayMode(nextMode))
  }

  const leftSubcomponents = (
    <>
      <Button
        appearance="transparent"
        icon={isGridModeEnabled ? <Grid /> : <TaskListLtr />}
        checked={isGridModeEnabled}
        onClick={onClickGridToggle}
        className={isSearchBoxShown ? '!hidden md:!flex' : ''}
      >
        {t(isGridModeEnabled ? 'project_top_bar.grid_mode' : 'project_top_bar.list_mode')}
      </Button>
    </>
  )

  const rightComponents = (
    <>
      <TopBarAddButton
        id="projectsTopBar_addNewProjectButton"
        text={t('project.create_project')}
        onClick={onCreateProject}
        className={cn('mx-1.5 my-0 h-8', isSearchBoxShown && '!hidden md:!flex')}
      />
      {shouldSearchBeShown && (
        <SearchBoxWithShortcut
          id="projectsTopBar_searchTextField"
          ref={searchBoxRef}
          value={textSearch}
          placeholder={t('project_top_bar.search_bar_placeholder')}
          onClear={hideSearchBox}
          onChange={onSearchBoxChange}
          onSearch={onSearch}
          className={cn('hidden flex-1 md:flex-initial', isSearchBoxShown && 'flex')}
        />
      )}
      {!isSearchBoxShown && shouldSearchBeShown && (
        <Tooltip
          content={<ShortcutTooltipContent label={searchButtonTooltip} tooltipKeys={SEARCH_KEYS} />}
          relationship="label"
        >
          <Button icon={<Search16Regular />} onClick={onClickSearch} />
        </Tooltip>
      )}
      <StyledFilterButton
        id="projectsTopBar_filtersButton"
        tooltip={filterButtonTooltip}
        filtersCount={filtersCount}
        onClear={() => dispatch(setDefaultFilterProjects())}
        onClick={showFPanel}
        ref={filterButtonRef}
      />
    </>
  )
  return (
    <>
      <TopRightBar
        className="border-none px-5 pt-3"
        title={filtersCount > 0 ? t('project_top_bar.filtering_title') : t('project_top_bar.title')}
        rightSubcomponents={rightComponents}
        leftSubcomponents={leftSubcomponents}
        shouldLoadTitle={!(isSearchBoxShown && isMobilePlatform)}
      />
      <ProjectsFiltersPanel isOpen={isFPanelOpen} onDismiss={hideFPanel} />
    </>
  )
})
