import { memo, useCallback, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { graphStateHelper } from '../../../common/src/helpers'
import { OfficeHeader, OfficeSection } from './OfficeSection'
import { PMHeader, PMSection } from './PMSection'
import { PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import { cn } from '@/modules/classnames'

export const OfficeSplitView = memo(
  ({
    items,
    graphItems,
    selectedGraphItem,
    onSelectItem,
    onSelectGraphItem,
    onCreateItem,
    onRenderLastItem,
    onRenderLastGraphItem,
    canBeEnabledOffice = true,
    loading = false,
    loadingGraph = false,
    PMFirst = true,
    placeholder,
    officePlaceholder,
    suggestedGraphPermissionLink,
    filterMode,
  }) => {
    const isGraphEnabled = useSelector(state => graphStateHelper.isGraphEnabled(state))
    const isEnabledOffice = isGraphEnabled && canBeEnabledOffice

    const [isCollapsedBottomPanel, setIsCollapsedBottomPanel] = useState(false)
    const [isResizing, setIsResizing] = useState(false)
    const bottomPanelRef = useRef(null)

    const onToggleBottomSection = useCallback(() => {
      if (bottomPanelRef.current?.isCollapsed()) {
        bottomPanelRef.current?.expand()
      } else {
        bottomPanelRef.current?.collapse()
      }
    }, [])

    const onCollapse = useCallback(() => setIsCollapsedBottomPanel(true), [])
    const onExpand = useCallback(() => setIsCollapsedBottomPanel(false), [])

    const components = [
      <PMSection
        key="pmSection"
        items={items}
        onSelectItem={onSelectItem}
        onCreateItem={isEnabledOffice ? onCreateItem : undefined}
        onRenderLastItem={onRenderLastItem}
        loading={loading}
        placeholder={placeholder}
        canBeEnabledOffice={canBeEnabledOffice}
        isBottomView={!PMFirst}
        onToggleBottomSection={onToggleBottomSection}
        filterMode={filterMode}
        show
        {...(!PMFirst && { ref: bottomPanelRef, onCollapse, onExpand })}
      />,
      ...(canBeEnabledOffice
        ? [
            <PanelResizeHandle
              key="resizeHandle"
              onDragging={setIsResizing}
              className={cn(
                'h-0.5 bg-pm-neutral-tertiary-alt transition-all active:bg-white',
                isResizing && 'h-1 bg-pm-theme-primary'
              )}
            />,
            <OfficeSection
              key="officeSection"
              items={graphItems}
              selectedItem={selectedGraphItem}
              onSelectItem={onSelectGraphItem}
              onRenderLastItem={onRenderLastGraphItem}
              loading={loadingGraph}
              placeholder={officePlaceholder}
              onToggleBottomSection={onToggleBottomSection}
              isBottomView={!!PMFirst}
              graphPermissionLink={suggestedGraphPermissionLink}
              show
              {...(PMFirst && { ref: bottomPanelRef, onCollapse, onExpand })}
            />,
          ]
        : []),
    ]

    const orderedComponents = PMFirst ? components : [...components].reverse()

    return (
      <>
        <PanelGroup direction="vertical" autoSaveId="OfficeSplitView">
          {orderedComponents}
          {isCollapsedBottomPanel &&
            (PMFirst ? (
              <OfficeHeader isBottomView onToggleBottomSection={onToggleBottomSection} show={false} />
            ) : (
              <PMHeader
                isBottomView
                onToggleBottomSection={onToggleBottomSection}
                show={false}
                onCreateItem={onCreateItem}
              />
            ))}
        </PanelGroup>
      </>
    )
  }
)
