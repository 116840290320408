import { useEffect, useRef } from 'react'
import { fromEvent } from 'rxjs'
import isHotkey from 'is-hotkey'

const HOT_KEYS = {
  SEARCH: 'mod+k',
  SEARCH_PROEJCT_LEFT_PANEL: 'alt+p',
  CREATE_ITEM: 'mod+i',
  CLEAR_SELECTION: 'esc',
  SELECT_ALL: 'mod+a',
  PRINT: 'mod+p',
  CALENDAR_MONTH: 'm',
  CALENDAR_WEEK: 'w',
  CALENDAR_DAY: 'd',
  FILTERS: 'alt+f',
  HOME: 'h',
  FIND_NOTES: 'mod+f',
}

export const isSearchHotKey = isHotkey(HOT_KEYS.SEARCH)
export const isSearchProjectLeftPanelHotKey = isHotkey(HOT_KEYS.SEARCH_PROEJCT_LEFT_PANEL)
export const isCreateItemHotKey = isHotkey(HOT_KEYS.CREATE_ITEM)
export const isClearSelectionHotKey = isHotkey(HOT_KEYS.CLEAR_SELECTION)
export const isSelectAllHotKey = isHotkey(HOT_KEYS.SELECT_ALL)
export const isPrintHotKey = isHotkey(HOT_KEYS.PRINT)
export const isCalendarMonthHotKey = isHotkey(HOT_KEYS.CALENDAR_MONTH)
export const isCalendarWeekHotKey = isHotkey(HOT_KEYS.CALENDAR_WEEK)
export const isCalendarDayHotKey = isHotkey(HOT_KEYS.CALENDAR_DAY)
export const isOpenFiltersHotKey = isHotkey(HOT_KEYS.FILTERS)
export const isGoToHomeHotKey = isHotkey(HOT_KEYS.HOME)
export const isFindNotesHotKey = isHotkey(HOT_KEYS.FIND_NOTES)

export const useHotkey = (eventChecker, handler, avoidInput = false) => {
  const subscriptionRef = useRef(null)
  useEffect(() => {
    subscriptionRef.current?.unsubscribe()
    subscriptionRef.current = fromEvent(window, 'keydown').subscribe(evt => {
      if (eventChecker(evt)) {
        if (
          avoidInput &&
          (evt.target.tagName === 'INPUT' || evt.target.tagName === 'TEXTAREA' || evt.target.contentEditable === 'true')
        ) {
          return
        }
        evt.preventDefault()
        handler(evt)
      }
    })
    return () => {
      subscriptionRef.current?.unsubscribe()
    }
  }, [avoidInput, eventChecker, handler])
}
