import React, { useCallback, useContext, useMemo, useRef, useState, useEffect } from 'react'
import { cn } from '@appfluence/classnames'
import _ from 'lodash'
import { CommentTextField } from '../textFields/CommentTextField'
import { KEY_CODE } from '../../common/src/helpers/keyboardHelper'
import { itemHelper, platformHelper, stateHelper } from '../../common/src/helpers'
import { ItemDetailContext, NewItemModalContext } from '../../contexts'
import { HiddenFileInput } from '../input/HiddenFileInput'
import { isCommentEmpty } from '../../helpers/commentHelper'
import { EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { useShortcut } from '../../hooks/useKonamiCheatCode'
import { SearchItemsPanel } from '../searchPanel/SearchItemsPanel'
import { getRelatedItems } from '../../common/src/actions/itemsAPI'
import { getItemLink } from '../../common/src/constants'
import { useTranslation } from 'react-i18next'
import { Button, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Spinner } from '@fluentui/react-components'
import { CheckmarkCircleFilled, SendFilled } from '@fluentui/react-icons'
import { AddSquare, Attach, DocumentMention, Mention, PersonAdd } from '../BundledIcons'
import { hasTouchScreen } from '@/helpers/deviceDetectionHelper'

const useKeyDownToFocus = focusContentEditable => {
  const focusAndPreventInput = useCallback(() => {
    setTimeout(focusContentEditable, 10)
  }, [focusContentEditable])
  useShortcut(KEY_CODE.R, ['altKey'], focusAndPreventInput)
}

export const ChatFooter = ({
  comment,
  onChangeComment,
  sendComment,
  onFilesToUpload,
  loading = false,
  loadingMessage,
  openFollowersPanel,
  itemID,
  isCurrentlyVisible = true,
  textFieldRef,
  focusContentEditable,
  isEditModeOn,
  cancelEdit,
  startEditingLastComment,
}) => {
  const fileInputRef = useRef(null)
  const { scrollBottom } = useContext(ItemDetailContext)
  const { t } = useTranslation()
  const empty = isCommentEmpty(comment)
  const newItemModalContext = useContext(NewItemModalContext)
  const modalIsDisplayed = newItemModalContext.newItemModalState.isDisplayed
  const [showMentionPanel, setShowMentionPanel] = useState(false)

  const [showItemPanelToSearch, setShowItemPanelToSearch] = useState(false)
  const handleExitItemPanel = useCallback(() => {
    setShowItemPanelToSearch(false)
  }, [])

  // Focus chat after first render
  useEffect(() => {
    if (!hasTouchScreen() && !modalIsDisplayed && isCurrentlyVisible) {
      focusContentEditable()
    }
  }, [itemID, modalIsDisplayed, isCurrentlyVisible, focusContentEditable])

  useKeyDownToFocus(focusContentEditable)

  const handleSendComment = useCallback(
    event => {
      sendComment(event)
      focusContentEditable()
    },
    [sendComment, focusContentEditable]
  )

  // "Editable" only updates when some own props change so
  // these values need to use refs.
  const isEditModeOnRef = useRef(isEditModeOn)
  useEffect(() => {
    isEditModeOnRef.current = isEditModeOn
  }, [isEditModeOn])

  const commentRef = useRef(comment)
  useEffect(() => {
    commentRef.current = comment
  }, [comment])

  const startEditingLastCommentRef = useRef(startEditingLastComment)
  useEffect(() => {
    startEditingLastCommentRef.current = startEditingLastComment
  }, [startEditingLastComment])

  const onKeyDown = useCallback(
    event => {
      // Disable formatting shortcuts
      if (event.ctrlKey || event.metaKey) {
        switch (event.key) {
          case 'B':
          case 'b':
          case 'I':
          case 'i':
          case 'U':
          case 'u':
            event.preventDefault()
            return
        }
      }
      if (!platformHelper.isMobile() && event.keyCode === KEY_CODE.ENTER && !event.shiftKey) {
        handleSendComment(event)
      } else if (isEditModeOnRef.current && event.key === 'Escape') {
        cancelEdit()
      } else if (!isEditModeOnRef.current && event.key === 'ArrowUp' && !commentRef.current.trim()) {
        event.preventDefault()
        startEditingLastCommentRef.current()
      }
    },
    [cancelEdit, handleSendComment]
  )

  const onFocus = useCallback(() => {
    // this delay is tricky... we should detect when the mobile keyboard appear,
    // and then call the method below
    scrollBottom()
  }, [scrollBottom])

  const contentEditableProps = useMemo(
    () => ({
      id: 'itemDetail_chatTextField',
      onKeyDown,
      onFocus,
    }),
    [onFocus, onKeyDown]
  )

  const selectFiles = useCallback(() => {
    if (empty) {
      fileInputRef.current.click()
    }
  }, [fileInputRef, empty])

  const onSelectFiles = useCallback(
    async evt => {
      const fileList = evt.target.files
      const files = Array.from(fileList)
      if (_.isEmpty(files)) {
        return
      }
      onFilesToUpload(files)
    },
    [onFilesToUpload]
  )

  const onClickAddFriend = useCallback(() => {
    if (empty) {
      openFollowersPanel(EVENT_EXTRA.OPEN_FOLLOWERS_PANEL.MODE.CHAT_BOX)
    }
  }, [empty, openFollowersPanel])
  const onClickMentionUser = () => {
    setShowMentionPanel(true)
  }
  const onAddItem = useCallback(
    async item => {
      const prefix = empty ? 'See' : comment
      const itemID = itemHelper.getId(item)
      const text = `${prefix} ${getItemLink(itemID)}`
      onChangeComment(null, text)
      handleExitItemPanel()
    },
    [empty, comment, onChangeComment, handleExitItemPanel]
  )
  const fetchRelatedItems = useCallback(() => getRelatedItems(itemID), [itemID])
  const getRelatedItemsFromState = useMemo(() => _.partial(stateHelper.getRelatedItems, _, itemID), [itemID])

  return (
    <div className="relative box-border flex min-h-[62px] bg-pm-neutral-lighter px-2.5 py-[5px]">
      <div className={cn('relative z-10 flex flex-1 transition-opacity', loading ? 'opacity-0' : 'opacity-100')}>
        <div className="flex justify-center">
          <Menu>
            <MenuTrigger disableButtonEnhancement>
              <Button appearance="transparent" size="large" icon={<AddSquare />} />
            </MenuTrigger>
            <MenuPopover>
              <MenuList>
                <MenuItem icon={<Attach />} onClick={selectFiles}>
                  {t('chat.upload_button_tooltip')}
                </MenuItem>
                <MenuItem icon={<PersonAdd />} onClick={onClickAddFriend}>
                  {t('chat.collaborator_button_tooltip')}
                </MenuItem>
                <MenuItem icon={<Mention />} onClick={onClickMentionUser}>
                  {t('chat.mention_button_tooltip')}
                </MenuItem>
                <MenuItem icon={<DocumentMention />} onClick={() => setShowItemPanelToSearch(true)}>
                  {t('chat.item_button_tooltip')}
                </MenuItem>
              </MenuList>
            </MenuPopover>
          </Menu>
        </div>
        <CommentTextField
          comment={comment}
          onChange={onChangeComment}
          contentEditableProps={contentEditableProps}
          ref={textFieldRef}
          showMentionPanel={showMentionPanel}
          setShowMentionPanel={setShowMentionPanel}
        />
        <div className="flex justify-center">
          <Button
            size="large"
            id="itemDetail_chatSendButton"
            appearance="transparent"
            icon={isEditModeOn ? <CheckmarkCircleFilled /> : <SendFilled />}
            onClick={handleSendComment}
            title={t(isEditModeOn ? 'chat.confirm_edit_button_tooltip' : 'chat.send_button_tooltip')}
          />
        </div>
      </div>
      {loading && (
        <Spinner
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          size="large"
          label={loadingMessage ?? t('item_detail.uploading')}
        />
      )}
      <HiddenFileInput id="file" ref={fileInputRef} multiple onChange={onSelectFiles} />
      <SearchItemsPanel
        isOpen={showItemPanelToSearch}
        onDismiss={handleExitItemPanel}
        onAddItem={onAddItem}
        onSelectItemProp={onAddItem}
        getInitialItems={fetchRelatedItems}
        getInitialItemsFromState={getRelatedItemsFromState}
        onDismissed={focusContentEditable}
      />
    </div>
  )
}
