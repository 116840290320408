import React, { memo, useCallback, useMemo } from 'react'
import { cn } from '@appfluence/classnames'
import { FontWeights, mergeStyleSets, IconButton, Modal, Text } from '@fluentui/react'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { useRouter } from '../../hooks/useRouterHook'
import { CYPRESS_ID } from '../../constants/cypress'
import { useTheme } from 'styled-components'

const classNames = {
  modalContainer: 'FullScreenModalContainer-modalContainer',
  modalScrollableContent: 'FullScreenModalContainer-modalScrollableContent',
  container: 'FullScreenModalContainer-container',
  header: 'FullScreenModalContainer-header',
  body: 'FullScreenModalContainer-body',
  section: 'FullScreenModalContainer-section',
  sectionHeader: 'FullScreenModalContainer-sectionHeader',
  sectionBody: 'FullScreenModalContainer-sectionBody',
}

const useClassNames = (narrow = false) => {
  const theme = useTheme()
  return useMemo(
    () =>
      mergeStyleSets({
        modalContainer: [
          classNames.modalContainer,
          {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            width: '100%',
            height: '100%',
            maxWidth: 'inherit',
            maxHeight: 'inherit',
          },
        ],
        modalScrollableContent: [
          classNames.modalScrollableContent,
          {
            display: 'flex',
            justifyContent: 'center',
          },
        ],
        container: [
          classNames.container,
          {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          },
        ],
        header: [
          classNames.header,
          theme.fonts.xLarge,
          {
            height: '40px',
            padding: '0 16px 0 calc(env(titlebar-area-x, 0px) + 16px)',
            display: 'flex',
            alignItems: 'center',
            flex: '0 0 auto',
            justifyContent: 'space-between',
            color: theme.palette.neutralPrimary,
            fontWeight: FontWeights.semibold,
            boxShadow: 'var(--shadow4)',
          },
        ],
        body: [
          classNames.body,
          {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            margin: 'auto',
            boxSizing: 'border-box',
            padding: '0px 16px',
            width: '100%',
            maxWidth: '900px',
            overflowY: 'auto',
          },
          narrow && {
            maxWidth: '100%',
            boxSizing: 'border-box',
          },
        ],
      }),
    [narrow, theme.fonts.xLarge, theme.palette.neutralPrimary]
  )
}

export const FullScreenModalSection = ({ children, className }) => <div className={className}>{children}</div>
export const FullScreenModalSectionHeader = ({ children, className }) => (
  <Text as="div" variant="xLarge" className={`my-3 block text-pm-black ${className ?? ''}`}>
    {children}
  </Text>
)
export const FullScreenModalSectionBody = ({ children, className }) => <div className={className}>{children}</div>

const cancelIcon = { iconName: 'Cancel' }
const backIcon = { iconName: 'Back' }

export const FullScreenModalContainer = memo(
  ({
    isOpen = true,
    modalContainerClassName,
    modalScrollableClassName,
    containerClassName,
    headerClassName,
    bodyClassName,
    children,
    onDismiss,
    ...rest
  }) => {
    const { history, location } = useRouter()
    const narrow = useNarrowWidth()
    const { modalContainer, modalScrollableContent, container, header, body } = useClassNames(narrow)

    const onBack = useCallback(() => {
      history.goBack()
    }, [history])

    const onDismissByDefault = useCallback(() => {
      const from = location?.state?.from || getRelativeURLKeepingQuerySearch.projects()
      history.push(from)
    }, [location, history])

    const onLayerDidMount = useCallback(() => {
      const modalScrollableContent = document.getElementsByClassName(classNames.modalScrollableContent)[0]
      if (modalScrollableContent) {
        // Fix Safari and Firefox mobile
        modalScrollableContent.removeAttribute('data-is-scrollable')
      }
    }, [])

    const finalOnDismiss = onDismiss || onDismissByDefault
    return (
      <Modal
        isOpen={isOpen}
        onDismiss={finalOnDismiss}
        isBlocking
        containerClassName={cn(modalContainer, modalContainerClassName)}
        scrollableContentClassName={cn(modalScrollableContent, modalScrollableClassName)}
        layerProps={{ onLayerDidMount: onLayerDidMount }}
        {...rest}
      >
        <div className={cn(container, containerClassName)}>
          <div className={cn(header, headerClassName)}>
            {history.length && <IconButton iconProps={backIcon} onClick={onBack} />}
            <IconButton iconProps={cancelIcon} onClick={finalOnDismiss} data-automation-id={CYPRESS_ID.BACK} />
          </div>
          <div className={cn(body, bodyClassName)}>{children}</div>
        </div>
      </Modal>
    )
  }
)
