import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { UsersCollection } from '../users/usersCollection/UsersCollection'
import { itemHelper, stateHelper, userHelper } from '../../common/src/helpers'
import { OwnerPanel } from './OwnerPanel'
import { EVENT_EXTRA } from '../../common/src/eventTracking/amplitudeEvents'
import { useUserForEmail } from '../../common/src/hooks/usersHooks'
import { UserCollectionCell } from '../users/usersCollection/UserCollectionCell'
import { useUsersMenuProps } from '../../hooks/usersMenuPropsHooks'
import { getProjectIfNeeded } from '../../common/src/actions/combinedAPI'
import { useDispatch } from 'react-redux'

export const PeopleSection = ({
  item,
  onChangeOwner,
  addFollowerTooltip,
  readOnly = false,
  containerRef,
  openFollowersPanel,
  projectIdd = 0,
}) => {
  const [isOpenOwnerView, setOpenOwnerView] = useState(false)

  const dispatch = useDispatch()
  const ownerEmail = itemHelper.getOwnerUsername(item)
  const followers = itemHelper.getFollowers(item, false)
  const projectID = itemHelper.getProjectIdd(item)
  const owner = useUserForEmail(ownerEmail)
  const project = useSelector(state => stateHelper.getProject(state, projectID))

  const showOwnerView = useCallback(() => {
    setOpenOwnerView(true)
    if (projectIdd) {
      dispatch(getProjectIfNeeded({ projectID: projectIdd, memberships: true }))
    }
  }, [projectIdd, dispatch])

  const hideOwnerView = useCallback(() => {
    setOpenOwnerView(false)
  }, [setOpenOwnerView])

  const otherFollowers = useMemo(() => {
    if (!followers) {
      return []
    }
    return followers
      .filter(u => userHelper.getEmail(u) !== ownerEmail)
      .sortBy(follower => {
        return userHelper.getEmail(follower)
      })
      .toArray()
  }, [followers, ownerEmail])

  const onClickUserCollection = useCallback(() => {
    openFollowersPanel(EVENT_EXTRA.OPEN_FOLLOWERS_PANEL.MODE.CHAT_BOX)
    if (projectIdd) {
      dispatch(getProjectIfNeeded({ projectID: projectIdd, memberships: true }))
    }
  }, [dispatch, openFollowersPanel, projectIdd])

  const addButtonProps = useMemo(
    () => ({
      id: 'itemDetail_addUserButton',
      title: addFollowerTooltip,
    }),
    [addFollowerTooltip]
  )

  const ownerInArray = useMemo(() => [owner], [owner])
  const menuProps = useUsersMenuProps({ users: owner ? ownerInArray : null, object: item })

  return (
    <div className="flex items-center" ref={containerRef}>
      <UserCollectionCell
        className="mr-2"
        user={owner}
        readOnly={readOnly}
        onClick={showOwnerView}
        personaProps={ownerPersonaProps}
        hidePersonaDetails
      />
      <UsersCollection
        addButtonProps={addButtonProps}
        users={otherFollowers}
        max={5}
        showAddCell={otherFollowers.length < 3}
        onClick={onClickUserCollection}
        readOnly={readOnly}
      />
      <OwnerPanel
        user={owner}
        project={project}
        onChange={onChangeOwner}
        isOpen={isOpenOwnerView}
        onDismiss={hideOwnerView}
        menuProps={menuProps}
      />
    </div>
  )
}

const ownerPersonaProps = {
  avatar: { size: 40 },
}
