import { fromJS, Map, List } from 'immutable'
import _ from 'lodash'
import { generateActionTypeString, PERSIST_REHYDRATE_ACTION_TYPE, API_EVENT } from '../helpers/reducerHelper'
import { dateHelper } from '../helpers'
import { FILTER_REDUCER_KEYS as KEYS } from './filtersKeys'
import {
  STATE_FILTER,
  ACTION_TYPES,
  PROJECTS_SORT_TYPE,
  ITEMS_SORT_TYPE,
  ITEMS_DATE_TYPE,
  CONDITION_KEYS,
  FREQUENT_ITEM_FILTERS,
  TAGS_MODE_KEYS,
  PROJECTS_SHOW_ARCHIVED_OPTIONS,
  GROUP_BY,
  PROJECTS_MODE_KEYS,
  TEXT_MODE_KEYS,
} from '../actions/filtersActions'
import { GENERIC_ACTION_TYPE } from '../actions/genericActions'
import customFilterHelper from '../helpers/customFilterHelper'
import { APIS, PM_API_RESOURCES } from '../constants'

const LOCAL_ACTION_TYPES = {
  GET_SETTINGS: generateActionTypeString(APIS.PM, PM_API_RESOURCES.SETTINGS, API_EVENT.SUCCESS),
}

const projectsBase = fromJS({
  [KEYS.SORT]: PROJECTS_SORT_TYPE.TIMESTAMP,
  [KEYS.SORT_ASCENDING]: true,
  [KEYS.TEXT]: '',
  [KEYS.USER_GROUP]: null,
  [KEYS.TAGS]: [],
  [KEYS.TAGS_MODE]: TAGS_MODE_KEYS.INCLUDE,
  [KEYS.SHOW_ARCHIVED]: PROJECTS_SHOW_ARCHIVED_OPTIONS.ONLY_ACTIVE,
})
const matrixBase = fromJS({
  [KEYS.SORT]: ITEMS_SORT_TYPE.INDEX,
  [KEYS.SORT_ASCENDING]: false,
  [KEYS.STARRED_FIRST]: true,
  [KEYS.OWNERS]: [],
  [KEYS.NO_OWNER]: false,
  [KEYS.STATE]: STATE_FILTER.UNFINISHED,
  [KEYS.FOLLOWED]: false,
  [KEYS.QUADRANT]: -1,
  [KEYS.DATE_TYPE]: ITEMS_DATE_TYPE.LAST_MODIFIED_DATE,
  [KEYS.PROGRESS_CONDITION]: CONDITION_KEYS.GREATER_THAN,
  [KEYS.PROGRESS]: 0,
  [KEYS.TAGS]: [],
  [KEYS.TAGS_MODE]: TAGS_MODE_KEYS.INCLUDE,
  [KEYS.TEXT]: '',
  [KEYS.TEXT_MODE]: TEXT_MODE_KEYS.FULL_TEXT,
  [KEYS.PROJECT_IDS]: [],
  [KEYS.PROJECTS_MODE]: PROJECTS_MODE_KEYS.INCLUDE,
  [KEYS.STARRED_PROJECT]: false,
  [KEYS.START_DATE]: null, //timestamp in secs
  [KEYS.END_DATE]: null, //timestamp in secs
  [KEYS.GROUP_BY]: GROUP_BY.NONE,
})

const projectListBase = matrixBase.set(KEYS.SORT, ITEMS_SORT_TYPE.LAST_MODIFIED_TIMESTAMP)
const projectKanbanBase = matrixBase.withMutations(m => {
  m.set(KEYS.SORT, ITEMS_SORT_TYPE.LAST_MODIFIED_TIMESTAMP)
  m.set(KEYS.STATE, STATE_FILTER.ALL_ITEMS)
})
const searchBase = matrixBase.set(KEYS.SORT, ITEMS_SORT_TYPE.LAST_MODIFIED_TIMESTAMP).set(KEYS.STARRED_FIRST, false)
const resourcesPanelBase = matrixBase
  .set(KEYS.SORT, ITEMS_SORT_TYPE.LAST_MODIFIED_TIMESTAMP)
  .set(KEYS.STARRED_FIRST, false)
const oneOnOneBase = matrixBase.set(KEYS.SORT, ITEMS_SORT_TYPE.LAST_MODIFIED_TIMESTAMP).set(KEYS.STARRED_FIRST, false)
const inboxBase = matrixBase.set(KEYS.SORT, ITEMS_SORT_TYPE.LAST_MODIFIED_TIMESTAMP)
const globalCalendarBase = matrixBase.withMutations(m => {
  m.set(KEYS.DATE_TYPE, ITEMS_DATE_TYPE.DUE_DATE)
  m.set(KEYS.START_DATE, 1)
})

export const base = Map({
  [KEYS.PROJECTS]: projectsBase,
  [KEYS.PROJECTS_PANEL]: projectsBase,
  [KEYS.PROJECT_MATRIX]: matrixBase,
  [KEYS.PROJECT_LIST]: projectListBase,
  [KEYS.PROJECT_KANBAN]: projectKanbanBase,
  [KEYS.SEARCH]: searchBase,
  [KEYS.RESOURCES_PANEL]: resourcesPanelBase,
  [KEYS.ONE_ON_ONE]: oneOnOneBase,
  [KEYS.INBOX]: inboxBase,
  [KEYS.GLOBAL_CALENDAR]: globalCalendarBase,
  [KEYS.GLOBAL_GANTT]: globalCalendarBase,
  [KEYS.SHOW_RECURRENT_ITEMS_HOURS_AHEAD]: null,
})

const persistentSorts = [
  KEYS.PROJECTS,
  KEYS.PROJECTS_PANEL,
  KEYS.PROJECT_MATRIX,
  KEYS.PROJECT_LIST,
  KEYS.SEARCH,
  KEYS.ONE_ON_ONE,
  KEYS.INBOX,
]

// reduceEffect methods should return the state
const reduceEffect = {
  [ACTION_TYPES.SET_DEFAULT]: () => base,
  // Projects
  [ACTION_TYPES.SET_DEFAULT_PROJECTS]: ({ payload }, state) => {
    const { filtersType } = payload
    return state.set(filtersType, fromJS(projectsBase))
  },
  [ACTION_TYPES.SET_PROJECTS_SORT_TYPE]: ({ payload }, state) => {
    const { sortType, filtersType } = payload
    return state.setIn([filtersType, KEYS.SORT], sortType)
  },
  [ACTION_TYPES.SET_PROJECTS_SORT_ASCENDING]: ({ payload }, state) => {
    const { isAscending, filtersType } = payload
    return state.setIn([filtersType, KEYS.SORT_ASCENDING], isAscending)
  },
  [ACTION_TYPES.SET_PROJECTS_TEXT]: ({ payload }, state) => {
    const { text, filtersType } = payload
    return state.setIn([filtersType, KEYS.TEXT], text)
  },
  [ACTION_TYPES.SET_PROJECTS_USER_GROUP]: ({ payload }, state) => {
    const { userGroupID, filtersType } = payload
    return state.setIn([filtersType, KEYS.USER_GROUP], userGroupID)
  },
  [ACTION_TYPES.SET_PROJECTS_TAGS]: ({ payload }, state) => {
    const { tagNames, filtersType } = payload
    return state.setIn([filtersType, KEYS.TAGS], fromJS(tagNames))
  },
  [ACTION_TYPES.SET_PROJECTS_TAGS_MODE]: ({ payload }, state) => {
    const { tagsMode, filtersType } = payload
    return state.setIn([filtersType, KEYS.TAGS_MODE], tagsMode)
  },
  [ACTION_TYPES.ADD_PROJECT_TAG]: ({ payload }, state) => {
    const { tagName, filtersType } = payload
    const tagSet = state.getIn([KEYS.PROJECTS, KEYS.TAGS]).toSet()
    return state.setIn([filtersType, KEYS.TAGS], tagSet.add(tagName))
  },
  [ACTION_TYPES.REMOVE_PROJECT_TAG]: ({ payload }, state) => {
    const { tagName, filtersType } = payload
    const tagSet = state.getIn([KEYS.PROJECTS, KEYS.TAGS]).toSet()
    return state.setIn([filtersType, KEYS.TAGS], tagSet.remove(tagName))
  },
  [ACTION_TYPES.SET_PROJECTS_SHOW_ARCHIVED]: ({ payload }, state) => {
    const { showArchived, filtersType } = payload
    return state.setIn([filtersType, KEYS.SHOW_ARCHIVED], showArchived)
  },

  //Items
  [ACTION_TYPES.SET_DEFAULT_ITEMS]: ({ payload }, state) => {
    const { filtersType } = payload
    return state.set(filtersType, base.get(filtersType))
  },
  [ACTION_TYPES.SET_ITEMS_SORT_TYPE]: ({ payload }, state) => {
    const { filtersType, sortType } = payload
    return state.setIn([filtersType, KEYS.SORT], sortType)
  },
  [ACTION_TYPES.SET_ITEMS_SORT_ASCENDING]: ({ payload }, state) => {
    const { filtersType, isAscending } = payload
    return state.setIn([filtersType, KEYS.SORT_ASCENDING], isAscending)
  },
  [ACTION_TYPES.SET_ITEMS_STARRED_FIRST]: ({ payload }, state) => {
    const { filtersType, starredFirst } = payload
    return state.setIn([filtersType, KEYS.STARRED_FIRST], starredFirst)
  },
  [ACTION_TYPES.SET_ITEMS_STATE]: ({ payload }, state) => {
    const { filtersType, itemsState } = payload
    return state.setIn([filtersType, KEYS.STATE], itemsState)
  },
  [ACTION_TYPES.SET_ITEMS_QUADRANT]: ({ payload }, state) => {
    const { filtersType, quadrant } = payload
    return state.setIn([filtersType, KEYS.QUADRANT], quadrant)
  },
  [ACTION_TYPES.SET_ITEMS_TEXT]: ({ payload }, state) => {
    const { filtersType, text } = payload
    return state.setIn([filtersType, KEYS.TEXT], text)
  },
  [ACTION_TYPES.SET_ITEMS_TEXT_MODE]: ({ payload }, state) => {
    const { filtersType, textMode } = payload
    return state.setIn([filtersType, KEYS.TEXT_MODE], textMode)
  },
  [ACTION_TYPES.SET_ITEMS_OWNERS]: ({ payload }, state) => {
    const { filtersType, emails } = payload
    return state.setIn([filtersType, KEYS.OWNERS], fromJS(emails))
  },
  [ACTION_TYPES.ADD_ITEM_OWNER]: ({ payload }, state) => {
    const { filtersType, email } = payload
    const ownerSet = state.getIn([filtersType, KEYS.OWNERS]).toSet()
    return state.setIn([filtersType, KEYS.OWNERS], ownerSet.add(email))
  },
  [ACTION_TYPES.REMOVE_ITEM_OWNER]: ({ payload }, state) => {
    const { filtersType, email } = payload
    const ownerSet = state.getIn([filtersType, KEYS.OWNERS]).toSet()
    return state.setIn([filtersType, KEYS.OWNERS], ownerSet.remove(email))
  },
  [ACTION_TYPES.SET_ITEMS_NO_OWNER]: ({ payload }, state) => {
    const { filtersType, noOwner } = payload
    return state.setIn([filtersType, KEYS.NO_OWNER], noOwner)
  },
  [ACTION_TYPES.SET_ITEMS_FOLLOWED]: ({ payload }, state) => {
    const { filtersType, followed } = payload
    return state.setIn([filtersType, KEYS.FOLLOWED], followed)
  },
  [ACTION_TYPES.SET_ITEMS_DATE_TYPE]: ({ payload }, state) => {
    const { filtersType, dateType } = payload
    return state.setIn([filtersType, KEYS.DATE_TYPE], dateType)
  },
  [ACTION_TYPES.SET_ITEMS_START_DATE_TIMESTAMP]: ({ payload }, state) => {
    const { filtersType, timestamp } = payload
    return state.setIn([filtersType, KEYS.START_DATE], timestamp)
  },
  [ACTION_TYPES.SET_ITEMS_END_DATE_TIMESTAMP]: ({ payload }, state) => {
    const { filtersType, timestamp } = payload
    return state.setIn([filtersType, KEYS.END_DATE], timestamp)
  },
  [ACTION_TYPES.SET_ITEMS_PROGRESS]: ({ payload }, state) => {
    const { filtersType, progress, condition } = payload
    state = state.setIn([filtersType, KEYS.PROGRESS_CONDITION], condition)
    return state.setIn([filtersType, KEYS.PROGRESS], progress)
  },
  [ACTION_TYPES.SET_ITEMS_TAGS]: ({ payload }, state) => {
    const { filtersType, tagNames } = payload
    return state.setIn([filtersType, KEYS.TAGS], fromJS(tagNames))
  },
  [ACTION_TYPES.SET_ITEMS_TAGS_MODE]: ({ payload }, state) => {
    const { filtersType, tagsMode } = payload
    return state.setIn([filtersType, KEYS.TAGS_MODE], tagsMode)
  },
  [ACTION_TYPES.ADD_ITEM_TAG]: ({ payload }, state) => {
    const { filtersType, tagName } = payload
    const tagSet = state.getIn([filtersType, KEYS.TAGS]).toSet()
    return state.setIn([filtersType, KEYS.TAGS], tagSet.add(tagName))
  },
  [ACTION_TYPES.REMOVE_ITEM_TAG]: ({ payload }, state) => {
    const { filtersType, tagName } = payload
    const tagSet = state.getIn([filtersType, KEYS.TAGS]).toSet()
    return state.setIn([filtersType, KEYS.TAGS], tagSet.remove(tagName))
  },
  [ACTION_TYPES.SET_ITEM_PROJECT]: ({ payload }, state) => {
    const { filtersType, projectIds } = payload
    return state.setIn([filtersType, KEYS.PROJECT_IDS], projectIds)
  },
  [ACTION_TYPES.SET_ITEM_PROJECTS_MODE]: ({ payload }, state) => {
    const { filtersType, mode } = payload
    return state.setIn([filtersType, KEYS.PROJECTS_MODE], mode)
  },
  [ACTION_TYPES.SET_ITEM_STARRED_PROJECT]: ({ payload }, state) => {
    const { filtersType, value } = payload
    return state.setIn([filtersType, KEYS.STARRED_PROJECT], value)
  },
  [ACTION_TYPES.SET_ITEMS_GROUP_BY]: ({ payload }, state) => {
    const { filtersType, groupBy } = payload
    return state.setIn([filtersType, KEYS.GROUP_BY], groupBy)
  },
  [ACTION_TYPES.SET_FREQUENT_ITEMS]: ({ payload }, state) => {
    const { filterName } = payload
    const fn = frequentFilterHandler[filterName]
    return fn ? fn({ state, ...payload }) : state
  },
  [ACTION_TYPES.SET_CUSTOM_FILTER]: ({ payload }, state) => {
    return customFilterHandler(payload, state)
  },

  // Rehydrate
  [PERSIST_REHYDRATE_ACTION_TYPE]: ({ payload }) => {
    // Keeping only the sort values
    const filters = payload.filters
    if (!filters) {
      return base
    }
    return base.withMutations(_st => {
      _.forEach(persistentSorts, key => {
        const sort = filters.getIn([key, KEYS.SORT])
        const sortAscending = filters.getIn([key, KEYS.SORT_ASCENDING])
        const textMode = filters.getIn([key, KEYS.TEXT_MODE])
        if (sort) {
          // TODO: remove ternary when there are no more occurrences of https://sentry.prioritymatrix.com/organizations/appfluence/issues/3094/
          _st.setIn([key, KEYS.SORT], sort === 'project' ? ITEMS_SORT_TYPE.LAST_MODIFIED_TIMESTAMP : sort)
        }
        _st.setIn([key, KEYS.SORT_ASCENDING], sortAscending)
        if (textMode) {
          _st.setIn([key, KEYS.TEXT_MODE], textMode)
        }
      })
      return _st
    })
  },

  [GENERIC_ACTION_TYPE.CLEAR_ALL]: () => {
    return base
  },

  [LOCAL_ACTION_TYPES.GET_SETTINGS]: ({ payload }, state) => {
    const value = _.get(payload, 'show_recurrent_items_hours_ahead', null)
    return state.set(KEYS.SHOW_RECURRENT_ITEMS_HOURS_AHEAD, value)
  },
}

const getYesterdayDate = () => {
  const date = new Date()
  date.setDate(date.getDate() - 1)
  return date
}
const getWeekStartingDate = () => {
  const curr = new Date()
  const first = curr.getDate() - curr.getDay()
  return new Date(curr.setDate(first))
}
const getWeekEndingDate = () => {
  const curr = new Date()
  const last = curr.getDate() - curr.getDay() + 6
  return new Date(curr.setDate(last))
}
const addDays = (date, days) => {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

const customFilterHandler = ({ filtersType, customFilter }, state) => {
  const filter = state.get(filtersType).withMutations(f => {
    f.set(KEYS.TEXT, customFilterHelper.getText(customFilter) ?? matrixBase.get(KEYS.TEXT))
    f.set(KEYS.SORT, customFilterHelper.getSort(customFilter) ?? matrixBase.get(KEYS.SORT))
    f.set(KEYS.DATE_TYPE, customFilterHelper.getDateType(customFilter) ?? matrixBase.get(KEYS.DATE_TYPE))
    f.set(KEYS.START_DATE, customFilterHelper.getStartDate(customFilter) ?? matrixBase.get(KEYS.START_DATE))
    f.set(KEYS.END_DATE, customFilterHelper.getEndDate(customFilter) ?? matrixBase.get(KEYS.END_DATE))
    f.set(KEYS.PROGRESS, customFilterHelper.getProgress(customFilter) ?? matrixBase.get(KEYS.PROGRESS))
    f.set(
      KEYS.PROGRESS_CONDITION,
      customFilterHelper.getProgressCondition(customFilter) ?? matrixBase.get(KEYS.PROGRESS_CONDITION)
    )
    f.set(KEYS.OWNERS, customFilterHelper.getOwners(customFilter) ?? matrixBase.get(KEYS.OWNERS))
    f.set(KEYS.FOLLOWED, customFilterHelper.getFollowed(customFilter) ?? matrixBase.get(KEYS.FOLLOWED))
    f.set(KEYS.TAGS, customFilterHelper.getTags(customFilter) ?? matrixBase.get(KEYS.TAGS))
    f.set(KEYS.TAGS_MODE, customFilterHelper.getTagsMode(customFilter) ?? matrixBase.get(KEYS.TAGS_MODE))
    f.set(KEYS.PROJECT_IDS, customFilterHelper.getProjects(customFilter) ?? matrixBase.get(KEYS.PROJECT_IDS))
    f.set(KEYS.PROJECTS_MODE, customFilterHelper.getProjectsMode(customFilter) ?? matrixBase.get(KEYS.PROJECTS_MODE))
  })
  return state.set(filtersType, filter)
}

const frequentFilterHandler = {
  [FREQUENT_ITEM_FILTERS.MODIFIED_SINCE_YESTERDAY]: ({ filtersType, state }) => {
    const filter = state.get(filtersType).withMutations(f => {
      f.set(KEYS.DATE_TYPE, ITEMS_DATE_TYPE.LAST_MODIFIED_DATE)
      f.set(KEYS.SORT, ITEMS_SORT_TYPE.LAST_MODIFIED_TIMESTAMP)
      f.set(KEYS.END_DATE, dateHelper.dateToTimestampInSeconds(new Date()))
      f.set(KEYS.START_DATE, dateHelper.dateToTimestampInSeconds(dateHelper.dateToStartOfDay(getYesterdayDate())))
    })
    return state.set(filtersType, filter)
  },
  [FREQUENT_ITEM_FILTERS.OVERDUE]: ({ filtersType, state }) => {
    const filter = state.get(filtersType).withMutations(f => {
      f.set(KEYS.SORT, ITEMS_SORT_TYPE.DUE_DATE)
      f.set(KEYS.DATE_TYPE, ITEMS_DATE_TYPE.DUE_DATE)
      f.set(KEYS.STATE, STATE_FILTER.UNFINISHED)
      f.set(KEYS.END_DATE, dateHelper.dateToTimestampInSeconds(new Date()))
    })
    return state.set(filtersType, filter)
  },
  [FREQUENT_ITEM_FILTERS.MY_ITEMS]: ({ filtersType, state, meEmail }) => {
    const filter = state.get(filtersType).withMutations(f => {
      f.set(KEYS.OWNERS, fromJS([meEmail]))
    })
    return state.set(filtersType, filter)
  },
  [FREQUENT_ITEM_FILTERS.STARRED]: ({ filtersType, state }) => {
    const filter = state.get(filtersType).withMutations(f => {
      f.set(KEYS.TAGS_MODE, TAGS_MODE_KEYS.INCLUDE)
      f.set(KEYS.TAGS, List(['starred']))
    })
    return state.set(filtersType, filter)
  },
  [FREQUENT_ITEM_FILTERS.THIS_WEEK]: ({ filtersType, state }) => {
    const filter = state.get(filtersType).withMutations(f => {
      f.set(KEYS.DATE_TYPE, ITEMS_DATE_TYPE.DUE_DATE)
      f.set(KEYS.END_DATE, dateHelper.dateToTimestampInSeconds(getWeekEndingDate()))
      f.set(KEYS.START_DATE, dateHelper.dateToTimestampInSeconds(getWeekStartingDate()))
    })
    return state.set(filtersType, filter)
  },
  [FREQUENT_ITEM_FILTERS.NEXT_7_DAYS]: ({ filtersType, state }) => {
    const filter = state.get(filtersType).withMutations(f => {
      // From start of today to end of next 7 days
      f.set(KEYS.DATE_TYPE, ITEMS_DATE_TYPE.DUE_DATE)
      f.set(KEYS.END_DATE, dateHelper.dateToTimestampInSeconds(dateHelper.dateToEndOfDay(addDays(new Date(), 8))))
      f.set(KEYS.START_DATE, dateHelper.dateToTimestampInSeconds(dateHelper.dateToStartOfDay(new Date())))
    })
    return state.set(filtersType, filter)
  },
}

export const filters = (state = base, action) => {
  const { type } = action
  const effect = reduceEffect[type]
  if (_.isFunction(effect)) {
    state = effect(action, state)
  }
  return state
}
