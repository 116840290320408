import { Subject } from 'rxjs'
import type { Section } from '../views/home/HomeConstants'

interface IURLData {
  v: number
  type: string
  id: number
}

interface ILinkData {
  urlData: IURLData
  source?: string
  internalRelativePath?: string
  section?: Section
}

export const linkSubject = new Subject<ILinkData>()

export const SOURCES = {
  MATRIX: 'MATRIX',
  LIST: 'LIST',
  KANBAN: 'KANBAN',
  CALENDAR: 'CALENDAR',
  CHAT: 'CHAT',
  FEED: 'FEED',
  GANTT: 'GANTT',
  PROJECT_CREATION: 'PROJECT_CREATION',
  MOVE_ITEM_MODAL: 'MOVE_ITEM_MODAL',
  REPORTS: 'REPORTS',
  BROWSER_NOTIFICATION: 'BROWSER_NOTIFICATION',
  ITEM_RESOURCES: 'ITEM_RESOURCES',
  WINDOW_OPEN: 'WINDOW_OPEN',
  RESTORE_ITEMS: 'RESTORE_ITEMS',
  REDIRECT_VIEW: 'REDIRECT_VIEW',
  INAPP_NOTIFICATION: 'INAPP_NOTIFICATION',
} as const
