import _ from 'lodash'
import { memo, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { List } from 'immutable'
import { FILTER_REDUCER_KEYS } from '../../common/src/reducers/filtersKeys'
import { isFilteringItems } from '../../common/src/selectors/filtersSelectors'
import { useTranslation } from 'react-i18next'
import { IVIEW_PLACEHOLDER_TYPES } from '../../components/placeholder/IViewPlaceholderTypes'
import { itemHelper } from '@/common/src/helpers'
import { BasicItemList } from '@/components/item/basicList/BasicItemList'
import { Portal } from '@fluentui/react-components'
import { Droppable, DragDropContext } from '@hello-pangea/dnd'
import { ItemCell } from '@/components/itemCell/ItemCell'
import { sendItem } from '@/common/src/actions/combinedAPI'
import { computeQuadrantColors } from '@/components/matrix/MatrixUtils'
import { useIsDarkTheme } from '@/themes'

const COLUMNS = {
  TODO: 'TODO',
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
}

const SORTED_COLUMNS = [COLUMNS.TODO, COLUMNS.IN_PROGRESS, COLUMNS.DONE]

const ColumnMapping = {
  0: COLUMNS.TODO,
  100: COLUMNS.DONE,
}

const mapItemToColumn = item => {
  const completion = itemHelper.getCompletionPercentage(item)
  return ColumnMapping[completion] ?? COLUMNS.IN_PROGRESS
}

const useGroupedItems = (items, sortFn) => {
  const groupedItems = useMemo(() => {
    const allItems = (items || List()).toArray()
    const grouped = _.groupBy(allItems, mapItemToColumn)
    Object.values(grouped).forEach(items => {
      items.sort(sortFn)
    })
    return grouped
  }, [items, sortFn])
  return groupedItems
}

const filtersType = FILTER_REDUCER_KEYS.PROJECT_LIST

const useColors = ({ project }) => {
  const isDarkTheme = useIsDarkTheme()
  return useMemo(() => computeQuadrantColors({ project, quadrant: 0, isDarkTheme }), [project, isDarkTheme])
}

export const KanbanComponent = memo(
  ({ project, items, selectedItemId, onSelectItem, sortFn, loading, showOwner = true, readOnly }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const isFiltering = useSelector(state => isFilteringItems(state, filtersType))
    const groupedItems = useGroupedItems(items, sortFn)
    const colors = useColors({ project })

    const _placeholder = useMemo(() => {
      if (isFiltering) {
        return {
          title: t('item.filtering_placeholder.title'),
          message: t('item.filtering_placeholder.message'),
          type: IVIEW_PLACEHOLDER_TYPES.SEARCH,
        }
      }
      return {
        title: t('project.empty_placeholder.title'),
        message: t('project.empty_placeholder.message'),
        type: IVIEW_PLACEHOLDER_TYPES.NOT_FOUND,
      }
    }, [isFiltering, t])

    const columns = SORTED_COLUMNS.map(column => {
      const items = groupedItems[column] ?? []
      return (
        <KanbanColumn
          key={column}
          column={column}
          items={items}
          onSelectItem={onSelectItem}
          selectedItemId={selectedItemId}
          readOnly={readOnly}
          showOwner={showOwner}
          colors={colors}
        />
      )
    })

    const handleDragEnd = useCallback(
      ({ draggableId, destination, source, ...rest }) => {
        console.log(rest)
        if (source.droppableId === destination.droppableId) {
          return
        }

        const handlers = {
          [COLUMNS.TODO]: i => i.set(itemHelper.KEYS.COMPLETION_PERCENTAGE, 0),
          [COLUMNS.IN_PROGRESS]: i => i.set(itemHelper.KEYS.COMPLETION_PERCENTAGE, 50),
          [COLUMNS.DONE]: i => i.set(itemHelper.KEYS.COMPLETION_PERCENTAGE, 100),
        }

        const sourceList = groupedItems[source.droppableId]
        const originalItem = sourceList[source.index]
        const modifiedItem = handlers[destination.droppableId](originalItem)
        dispatch(sendItem(modifiedItem, true))
      },
      [groupedItems, dispatch]
    )

    return (
      <div className="relative flex flex-1 flex-row gap-2 bg-pm-neutral-lighter p-2">
        <DragDropContext onDragEnd={handleDragEnd}>{columns}</DragDropContext>
      </div>
    )
  }
)

const columnToTranslationKey = {
  [COLUMNS.TODO]: 'kanban.headers.to_do',
  [COLUMNS.IN_PROGRESS]: 'kanban.headers.in_progress',
  [COLUMNS.DONE]: 'kanban.headers.done',
}

const KanbanColumn = memo(({ column, items, onSelectItem, readOnly, selectedItemId, showOwner, colors }) => {
  const { t } = useTranslation()
  const headerStyle = { background: colors.header.background, color: colors.header.text }
  const headerText = t(columnToTranslationKey[column])
  const renderColumn = (provided, snapshot) => {
    return (
      <div className="flex h-full w-80 flex-col overflow-x-scroll rounded-[5px] bg-pm-white">
        <div className="flex w-full flex-col items-center justify-center" style={headerStyle}>
          <span className="font-bold">{headerText}</span>
        </div>
        <div className="flex w-full flex-1">
          <BasicItemList
            className="w-80"
            key={column}
            items={items}
            onClickItem={onSelectItem}
            selectedItemId={selectedItemId}
            draggable
            showOwner={showOwner}
            scrollerRef={provided.innerRef}
            {...provided.droppableProps}
          />
        </div>
      </div>
    )
  }

  return (
    <Droppable
      mode="virtual"
      droppableId={column}
      renderClone={(provided, snapshot, rubric) => (
        <Portal>
          <ItemCell
            item={items[rubric.source.index]}
            selected={items[rubric.source.index].get('id') === selectedItemId}
            narrow={false}
            readOnly={readOnly}
            innerRef={provided.innerRef}
            isDragging={snapshot.isDragging}
            showOwner={showOwner}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
          />
        </Portal>
      )}
    >
      {renderColumn}
    </Droppable>
  )
})
