import _ from 'lodash'
import { set, addMinutes, differenceInCalendarDays, intlFormatDistance, isBefore, isAfter, transpose } from 'date-fns'
import { tz } from '@date-fns/tz'

export const dateToStartOfDay = date => {
  const d = new Date(date)
  d.setHours(0, 0, 0, 0)
  return d
}

export const dateToEndOfDay = date => {
  const d = new Date(date)
  d.setHours(23, 59, 59, 999)
  return d
}

export const getMM = date => {
  const m = date.getMonth() + 1 + ''
  return m.length === 1 ? '0' + m : m
}

export const getDD = date => {
  const d = date.getDate() + ''
  return d.length === 1 ? '0' + d : d
}

export const getYYYYMMDD = date => `${date.getFullYear()}-${getMM(date)}-${getDD(date)}`
export const fromYYYYMMDD = str => {
  const [y, m, d] = str.split('-').map(_.parseInt)
  const date = new Date()
  date.setFullYear(y, m - 1, d)
  date.setHours(12, 0, 0, 0)
  return date
}

export const toDate = source => {
  let unix = source
  if (_.isString(unix)) {
    if (_.includes(unix, 'T')) {
      return new Date(unix)
    }
    unix = parseFloat(source)
  }
  return unix ? new Date(unix * 1000) : void 0
}

export const increaseHourToSecondsTimestamp = (timestamp, hours) => {
  if (!timestamp) {
    return 0
  }
  const newDate = new Date(timestamp * 1000)
  newDate.setHours(newDate.getHours() + hours)
  return newDate.getTime() / 1000
}

export const increaseDaysToSecondsTimestamp = (timestamp, days) => {
  const hoursPerDay = 24
  return increaseHourToSecondsTimestamp(timestamp, days * hoursPerDay)
}

export const dateToTimestampInSeconds = d => Math.floor(d && d.getTime() / 1000)

export const timestampInSecondsToIsoString = timestamp => {
  if (!timestamp) {
    return null
  }
  return toDate(timestamp).toISOString()
}

/**
 * We set allDay dates to 12PM UTC, to avoid errors in multi-timezone teams
 */
export const dateToAllDay = originalDate => {
  const offsetMinutes = -originalDate.getTimezoneOffset()
  const adjustedDate = set(originalDate, {
    hours: 12,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  })
  return addMinutes(adjustedDate, offsetMinutes)
}

export const allDayDateStringRelativeToToday = (date, now = new Date()) => {
  if (!date) {
    return null
  }
  const diff = differenceInCalendarDays(date, now)
  if (_.inRange(diff, -6, 6)) {
    const fmt = new Intl.RelativeTimeFormat(navigator.language, { numeric: 'auto' })
    return fmt.format(diff, 'day')
  }

  return intlFormatDistance(date, now, {
    locale: navigator.language,
  })
}

export const dateStringRelativeTo = (date, referenceDay = new Date()) => {
  if (!date) {
    return null
  }

  return intlFormatDistance(date, referenceDay, {
    locale: navigator.language,
  })
}

export const getDateColor = ({ startDate, endDate, positiveColor, negativeColor, now = new Date() }) => {
  if (!startDate && !endDate) {
    return null
  }

  if (!startDate) {
    return isBefore(endDate, now) ? positiveColor : negativeColor
  }

  if (!endDate) {
    return isBefore(startDate, now) ? negativeColor : positiveColor
  }

  if (isAfter(startDate, now) && isAfter(endDate, now)) {
    return null
  }

  if (isBefore(startDate, now) && isAfter(endDate, now)) {
    return positiveColor
  }

  return negativeColor
}

/**
 * https://docs.microsoft.com/en-us/graph/api/resources/datetimetimezone?view=graph-rest-1.0
 */
export const MicrosoftDateTimeTimeZone = {
  /**
   * Converts a datetime to the local timezone Date.
   * @param {Object} msDate - The MS Date object.
   * @param {string} msDate.dateTime - The datetime to convert, represented ad {date}T{time}. Example: 2017-08-29T04:00:00.0000000
   * @param {string} msDate.timeZone - The timezone of the datetime. Example: 'America/Sao_Paulo'
   * @returns {Date} The converted datetime in the local timezone.
   */
  toDate: ({ dateTime, timeZone }) => {
    const transposed = transpose(new Date(dateTime), tz(timeZone)) // https://date-fns.org/docs/transpose
    return new Date(transposed) // without this step, calculations (such as isSameDay) would be made in the timeZone above
  },
}
