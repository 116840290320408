import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { cn } from '@appfluence/classnames'
import { Text } from '../text/Text'
import { projectHelper } from '../../common/src/helpers'
import { QuadrantSelector } from '../input/quadrant/QuadrantSelector'
import { TagDetails } from '../tags/TagDetails'
import { useSingleAndDoubleClick } from '../../common/src/hooks/useSingleAndDoubleClick'
import { useProjectMembersForProject } from '../../common/src/hooks/usersHooks'
import { UsersCollection } from '../users/usersCollection/UsersCollection'
import { useNarrowWidth } from '../../hooks/useNarrowWidth'
import { useTranslation } from 'react-i18next'
import { fadeInDelayed, fadeInStyle } from '../../style/scAnimations'
import { FlexColumn, FlexRow } from '../layout/FlexContainer'
import { StarFilled } from '@fluentui/react-icons'

const StyledQuadrantSelector = styled(QuadrantSelector)`
  margin-left: 8px;

  // To align with text
  margin-top: 6px;

  cursor: pointer;
`

const Title = styled(Text).attrs({
  variant: 'medium',
  nowrap: true,
  block: true,
  className: 'font-semibold',
})`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 2px;
  margin-bottom: 4px;
  cursor: pointer;
  &.selected {
    color: ${p => p.theme.palette.neutralLight};
  }
`

const DetailsContainer = styled(FlexRow)`
  align-items: center;
  justify-content: center;

  margin-top: 2px;
  margin-bottom: 2px;
  ${props => (props.fadeIn ? fadeInStyle : '')};

  color: ${p => p.theme.palette.neutralSecondary};
  &.selected {
    color: ${p => p.theme.palette.neutralLight};
  }
`

const StyledTagDetails = styled(TagDetails).attrs(props => ({
  styles: {
    tagsContainer: {
      flexWrap: 'wrap',
      maxHeight: 22,
      overflow: 'hidden',
    },
  },
  tagProps: {
    primaryColor: `${props.selected ? props.theme.palette.neutralLight : props.theme.palette.neutralSecondary}`,
    secondaryColor: 'transparent',
    style: {
      fontSize: '9px',
    },
  },
}))``

const DueText = styled.div.attrs({})`
  margin-right: 8px;
  font-size: 10px;
  cursor: pointer;

  color: ${p => (p.color ? p.color : p.theme.palette.neutralSecondary)};
  &.selected {
    color: ${p => (p.color ? p.color : p.theme.palette.neutralLight)};
  }
`

const MiddleContainer = styled(FlexColumn)`
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  min-width: 0;
  overflow: hidden;
  margin-left: 16px;

  color: ${p => p.theme.palette.black};
  &.selected {
    color: ${p => p.theme.palette.white};
  }
`

const StyledUsersCollection = styled(UsersCollection)`
  justify-content: flex-end;
  align-self: center;

  @media (min-width: 768px) {
    margin-right: 8px;
  }

  animation: ${props =>
    props.fadeIn
      ? css`
          ${fadeInDelayed} 1s ease-out
        `
      : 'none'};

  min-width: 72px;
`

const Container = styled(FlexRow)`
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 0;
  box-sizing: border-box;
  outline: none;
  min-height: 91px;
  border-radius: 3px;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: ${p => p.theme.palette.neutralLight};
    }
  }

  &.selected {
    background: ${p => p.theme.palette.themePrimary};
  }

  &.isDragging {
    box-shadow: var(--shadow8);
    z-index: 1000;
  }
`

const getStyle = (provided, style) => {
  if (!style) {
    return provided.draggableProps.style
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  }
}

const scrollCellIntoView = element => element?.scrollIntoView({ behavior: 'instant', block: 'center' })

const ProjectCell_ = ({
  project,
  selected,
  onClick,
  onDoubleClick,
  onClickInSubcomponents,
  readOnly = false,
  isDragging = false,
  provided,
  className,
  style,
  counts,
  scrollIntoView,
  ...rest
}) => {
  const narrow = useNarrowWidth()
  const handleClick = useSingleAndDoubleClick(onClick, onDoubleClick, { prevent: false })
  const theme = useTheme()
  const { t } = useTranslation()

  const name = projectHelper.getName(project)
  const id = projectHelper.getIdd(project)
  const members = useProjectMembersForProject({ project, asArray: true })
  const notes = projectHelper.getNotes(project)
  const endText = projectHelper.getEndTextFromNow(project)
  const endColor = projectHelper.getEndColor(project, theme.palette.negative)
  const isStarred = projectHelper.isStarred(project)

  const [animateDetails] = useState(!notes)
  const [animateMembers] = useState(members.length === 0)

  const selectedClass = cn({ selected })
  const narrowClass = cn({ narrow })
  const containerClass = cn(className, selectedClass, narrowClass, { isDragging })
  const usersCollection =
    members.length > 0 ? (
      <StyledUsersCollection
        users={members}
        showAddCell={false}
        readOnly={true}
        className="w-24"
        fadeIn={animateMembers}
      />
    ) : (
      <div className="w-24"></div>
    )

  const containerRefObject = useRef(null)

  const containerRefCallback = useCallback(
    element => {
      containerRefObject.current = element
      const innerRef = provided.innerRef
      innerRef(element)
    },
    [provided.innerRef]
  )

  useEffect(() => {
    if (scrollIntoView) {
      setTimeout(() => scrollCellIntoView(containerRefObject.current), 0)
    }
  }, [scrollIntoView])

  return (
    <Container
      onClick={handleClick}
      className={containerClass}
      ref={containerRefCallback}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      data-automation-id={`project-cell-${id}`}
      style={getStyle(provided, style)}
      {...rest}
    >
      <StyledQuadrantSelector
        onClick={onClickInSubcomponents}
        size={32}
        project={project}
        readOnly={readOnly}
        counters={counts}
      />
      <MiddleContainer className={selectedClass}>
        <Title
          title={t('project_cell.navigation_button_tooltip')}
          id={`project-title-${id}`}
          onClick={onClickInSubcomponents}
          className={selectedClass}
        >
          {isStarred && <StarFilled className={(selected ? 'text-yellow-500' : 'text-yellow-400') + ' mr-1'} />}
          {name}
        </Title>
        {notes && (
          <DetailsContainer className={selectedClass} fadeIn={animateDetails}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              title={t('project_cell.navigation_button_tooltip')}
              onClick={onClickInSubcomponents}
              className="line-clamp-1 cursor-pointer text-xs md:line-clamp-2"
            >
              {notes}
            </div>
          </DetailsContainer>
        )}
        <DetailsContainer className={cn(selectedClass, '!mt-1 w-full')}>
          {endText && (
            <DueText
              title={t('project_cell.navigation_button_tooltip')}
              className={selectedClass}
              color={endColor}
              onClick={onClickInSubcomponents}
            >
              {endText}
            </DueText>
          )}
          <div className="flex w-full items-center">
            <StyledTagDetails
              className="mr-1 flex-1"
              object={project}
              readOnly={readOnly}
              selected={selected}
              hidePlaceholderWhenEmpty
              fadeIn
            />
            {narrow && <div className="h-7">{usersCollection}</div>}
          </div>
        </DetailsContainer>
      </MiddleContainer>
      {!narrow && usersCollection}
    </Container>
  )
}

export const ProjectCell = React.memo(ProjectCell_)
