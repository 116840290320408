import React, { forwardRef, memo, useCallback } from 'react'
import styled from 'styled-components'
import { Panel } from 'react-resizable-panels'
import { AppfluenceTitle } from '../../../components/title/AppfluenceTitle'
import { OfficeSectionBody, OfficeSectionHeader } from './OfficeSectionSubcomponents'
import { TopBarAddButton } from '../../../components/buttons/TopBarAddButton'
import { ItemVirtualizedTable } from '../../../components/table/ItemVirtualizedTable'
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button'
import { useTranslation } from 'react-i18next'
import { useConfig } from '../../../queries/config'

const AddButton = styled(TopBarAddButton)`
  margin-left: 12px;
`

const SAppfluenceTitle = styled(AppfluenceTitle)`
  flex: 1;
`

const ShowPMButton = styled(PrimaryButton).attrs(p => ({
  text: p.t('general.show'),
}))`
  min-width: 60px;
  height: 24px;
  padding: 0 4px;
`
const HidePMButton = styled(DefaultButton).attrs(p => ({
  text: p.t('general.hide'),
}))`
  min-width: 60px;
  height: 24px;
  padding: 0 4px;
`

export const PMHeader = ({ isBottomView, onToggleBottomSection, show, onCreateItem }) => {
  const { t } = useTranslation()
  const appfluenceName = useConfig().data?.app_display_name
  return (
    <OfficeSectionHeader>
      <SAppfluenceTitle title={appfluenceName} />
      {isBottomView && (
        <>
          {show ? (
            <HidePMButton t={t} onClick={onToggleBottomSection} />
          ) : (
            <ShowPMButton t={t} onClick={onToggleBottomSection} />
          )}
        </>
      )}
      {onCreateItem && <AddButton text={t('one_on_one.add_shared_task')} onClick={onCreateItem} />}
    </OfficeSectionHeader>
  )
}

export const PMSection = memo(
  forwardRef(
    (
      {
        items = [],
        onSelectItem,
        onCreateItem,
        onRenderLastItem,
        onToggleBottomSection,
        loading = false,
        placeholder,
        canBeEnabledOffice = false,
        isBottomView = false,
        filterMode,
        onCollapse,
        onExpand,
      },
      ref
    ) => {
      const onRowClick = useCallback(
        ({ rowData }) => {
          onSelectItem(rowData)
        },
        [onSelectItem]
      )
      return (
        <Panel
          ref={ref}
          collapsible={isBottomView}
          minSize={10}
          className="flex flex-col"
          onExpand={onExpand}
          onCollapse={onCollapse}
        >
          {canBeEnabledOffice && (
            <PMHeader
              isBottomView={isBottomView}
              onToggleBottomSection={onToggleBottomSection}
              show
              onCreateItem={onCreateItem}
            />
          )}
          <OfficeSectionBody>
            <ItemVirtualizedTable
              displayProject
              items={items}
              onRowClick={onRowClick}
              isSortEnabled
              loading={loading}
              placeholder={placeholder}
              filterMode={filterMode}
              showQuadrant
              queryingSearchAPI
              endReached={onRenderLastItem}
            />
          </OfficeSectionBody>
        </Panel>
      )
    }
  )
)
