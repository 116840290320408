// Items
import { FILTER_REDUCER_KEYS } from '../reducers/filtersKeys'
import { pickPredicate } from '../helpers/immutableHelpers'
import { base as filtersBase } from '../reducers/filtersReducer'
import { createProjectFilter } from '../helpers/projectsFiltersHelper'
import { createItemFilter } from '../helpers/itemsFiltersHelper'
import stateHelper from '../helpers/stateHelper'
import Immutable from 'immutable'
import { createSelector } from 'reselect'
export const isFiltering = (state, itemFiltersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  return isFilteringProjects(state) || isFilteringItems(state, itemFiltersType)
}

// PROJECTS

const projectsFiltersPickPredicate = pickPredicate(FILTER_REDUCER_KEYS.SORT, FILTER_REDUCER_KEYS.SORT_ASCENDING)
export const isFilteringProjects = (state, filtersType = FILTER_REDUCER_KEYS.PROJECTS) => {
  const filters = state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType])
  const pickedFilters = filters.filterNot(projectsFiltersPickPredicate)
  const pFiltersBase = filtersBase.get(filtersType)
  const pickedPFiltersBase = pFiltersBase.filterNot(projectsFiltersPickPredicate)
  return !pickedFilters.equals(pickedPFiltersBase)
}

export const getProjectsFilters = (state, filtersType = FILTER_REDUCER_KEYS.PROJECTS) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType])
}

export const getProjectsSortType = (state, filtersType = FILTER_REDUCER_KEYS.PROJECTS) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.SORT])
}

export const getProjectsSortAscending = (state, filtersType = FILTER_REDUCER_KEYS.PROJECTS) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.SORT_ASCENDING])
}

export const getProjectsTextFilter = (state, filtersType = FILTER_REDUCER_KEYS.PROJECTS) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.TEXT])
}

export const getProjectsUserGroupFilter = (state, filtersType = FILTER_REDUCER_KEYS.PROJECTS) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.USER_GROUP])
}

export const getProjectsTagsFilter = (state, filtersType = FILTER_REDUCER_KEYS.PROJECTS) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.TAGS])
}

export const getProjectsTagsModeFilter = (state, filtersType = FILTER_REDUCER_KEYS.PROJECTS) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.TAGS_MODE])
}

export const getProjectsShowArchivedFilter = (state, filtersType = FILTER_REDUCER_KEYS.PROJECTS) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.SHOW_ARCHIVED])
}

export const applyCurrentFiltersToProjects = (state, projects) => {
  const filters = getProjectsFilters(state)
  const projectFilter = createProjectFilter(filters)
  return projects && projects.filter(projectFilter)
}

// ITEMS
const itemsFiltersPickPredicate = pickPredicate(
  FILTER_REDUCER_KEYS.SORT,
  FILTER_REDUCER_KEYS.SORT_ASCENDING,
  FILTER_REDUCER_KEYS.DATE_TYPE
)
export const isFilteringItems = (state, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  const filters = state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType])
  const pickedFilters = filters.filterNot(itemsFiltersPickPredicate)
  const iFiltersBase = filtersBase.get(filtersType)
  const pickedIFiltersBase = iFiltersBase.filterNot(itemsFiltersPickPredicate)
  return !pickedFilters.equals(pickedIFiltersBase)
}

export const getItemsFilters = createSelector(
  (state, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType]),
  state => state.getIn([FILTER_REDUCER_KEYS.FILTERS, FILTER_REDUCER_KEYS.SHOW_RECURRENT_ITEMS_HOURS_AHEAD]),
  (filters, showRecurrentItemsHoursAhead) =>
    filters.set(FILTER_REDUCER_KEYS.SHOW_RECURRENT_ITEMS_HOURS_AHEAD, showRecurrentItemsHoursAhead)
)

export const getItemsSortType = (state, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.SORT])
}

export const getItemsSortAscending = (state, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.SORT_ASCENDING])
}

export const getItemsStarredFirst = (state, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.STARRED_FIRST])
}

export const getItemsTextFilter = (state, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.TEXT])
}

export const getItemsTextModeFilter = (state, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.TEXT_MODE])
}

export const getItemsStateFilter = (state, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.STATE])
}

export const getItemsQuadrantFilter = (state, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.QUADRANT])
}

export const getItemsOwnersFilter = (state, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.OWNERS])
}

export const getItemsNoOwnerFilter = (state, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.NO_OWNER])
}

export const getItemsFollowedFilter = (state, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.FOLLOWED])
}

export const getItemsDateTypeFilter = (state, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.DATE_TYPE])
}

export const getItemsStartDateTimestampFilter = (state, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.START_DATE])
}

export const getItemsEndDateTimestampFilter = (state, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.END_DATE])
}

export const getItemsProgressConditionFilter = (state, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.PROGRESS_CONDITION])
}
export const getItemsProgressFilter = (state, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.PROGRESS])
}

export const getItemsTagsFilter = (state, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.TAGS])
}

export const getItemsTagsModeFilter = (state, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.TAGS_MODE])
}

export const getItemsProjectFilter = (state, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.PROJECT_IDS])
}

export const getItemsProjectsMode = (state, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.PROJECTS_MODE])
}

export const getItemsStarredProjectFilter = (state, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.STARRED_PROJECT])
}

export const getItemsGroupByFilter = (state, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  return state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType, FILTER_REDUCER_KEYS.GROUP_BY])
}

export const getFetchFollowedItemsCondition = state =>
  state.getIn([FILTER_REDUCER_KEYS.FILTERS, FILTER_REDUCER_KEYS.PROJECT_MATRIX, FILTER_REDUCER_KEYS.FOLLOWED]) ||
  state.getIn([FILTER_REDUCER_KEYS.FILTERS, FILTER_REDUCER_KEYS.PROJECT_LIST, FILTER_REDUCER_KEYS.FOLLOWED])

export const applyCurrentFiltersToItems = (state, items, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  const filters = getItemsFilters(state, filtersType)
  const meEmail = stateHelper.getMeEmail(state)
  const itemFilter = createItemFilter(filters, meEmail)
  return items && items.filter(itemFilter)
}

export const getItemFiltersCount = (state, filtersType = FILTER_REDUCER_KEYS.PROJECT_MATRIX) => {
  const filters = state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType])
  const pickedFilters = filters.filterNot(itemsFiltersPickPredicate)
  const iFiltersBase = filtersBase.get(filtersType)
  const pickedIFiltersBase = iFiltersBase.filterNot(itemsFiltersPickPredicate)
  return pickedFilters.count((value, key) => !Immutable.is(value, pickedIFiltersBase.get(key)))
}

export const getProjectFiltersCount = (state, filtersType = FILTER_REDUCER_KEYS.PROJECTS) => {
  const filters = state.getIn([FILTER_REDUCER_KEYS.FILTERS, filtersType])
  const pickedFilters = filters.filterNot(projectsFiltersPickPredicate)
  const pFiltersBase = filtersBase.get(filtersType)
  const pickedPFiltersBase = pFiltersBase.filterNot(projectsFiltersPickPredicate)
  return pickedFilters.count((value, key) => !Immutable.is(value, pickedPFiltersBase.get(key)))
}
