import { useQuery } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { getSettings } from '../common/src/actions/configAPI'

interface Settings {
  automatic_icons: boolean
  automatic_dueDates: boolean
  automatic_urls: boolean
  promote_by_forwarding: boolean
  unlimited_history: boolean
  push_top_daily_item: boolean
  thread_emails: boolean
  link_to_outlook_web: boolean
  attach_email_object: boolean
  include_email_body: boolean
  show_recurrent_items_hours_ahead: number | null
}

export const useSettings = () => {
  const dispatch = useDispatch()
  return useQuery({
    queryKey: ['settings'],
    queryFn: async () => {
      const params = {} as Record<string, number>
      const res = await dispatch(getSettings(params))
      return res.payload as Settings
    },
    staleTime: 5 * 60 * 1000,
  })
}
