import { useDeferredValue } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { stateHelper } from '../../common/src/helpers'
import { useQuery } from '@tanstack/react-query'
import { oneProjectHandler as init } from '../../actions/viewInitializationActions'
import { isReadOnly } from '../../helpers/routeHelper'
import { getMatchParameterInteger } from '../../common/src/utils'
import { useSelectedItemId } from '../../hooks/PMHooks'
import { InvalidObjectComponent, useIsObjectInvalidToBeShown } from '../errorViews/InvalidObjectComponent'
import { ProjectMatrixView } from '../matrix/ProjectMatrixView'
import { PMCalendarView } from '../calendar/PMCalendarView'
import * as queryParamsHelper from '../../helpers/queryParamsHelper'
import { Feed } from '../feed/Feed'
import { GanttView } from '../gantt/v2/GanttView'
import { getFiltersTypeByMode, getProjectModeFromPathname, PROJECT_MODE } from '../../utils/projectModesUtils'
import { useItemSortDescriptor } from '../../common/src/hooks/filtersHooks'
import { useApplyFiltersToItems } from '../../hooks/itemHooks'
import { MatrixTableView } from '../matrixTable/MatrixTableView'
import { PMProjectAnalyticsReport } from '../reports/PMProjectAnalyticsReport'
import { withBusiness } from '../withBusiness'
import { KanbanView } from '../kanban/KanbanView'

const Gantt = withBusiness(GanttView)

const checkEmbeddedInProjectCreationModal = () => {
  try {
    const href = window.parent?.location.href ?? ''
    return href.includes('new_project')
  } catch (_err) {
    return false
  }
}

export const OneProjectMainViewHandler = ({ match, location }) => {
  const dispatch = useDispatch()
  const pid = getMatchParameterInteger(match, 'pid')
  const projectMode = getProjectModeFromPathname(location.pathname)
  const filtersType = getFiltersTypeByMode(projectMode)
  const project = useSelector(state => stateHelper.getProject(state, pid))
  const projectItems = useSelector(state => stateHelper.getItemsInProject(state, project))
  const items = useApplyFiltersToItems(projectItems, filtersType)
  const sortFnSync = useItemSortDescriptor(filtersType)
  const sortFn = useDeferredValue(sortFnSync)
  const readOnly = isReadOnly(location)
  const matrixPreviewMode = checkEmbeddedInProjectCreationModal() && readOnly
  const token = readOnly ? queryParamsHelper.getFastProject() : void 0
  const { error, isLoading: loading } = useQuery({
    queryKey: ['oneProjectHandler', pid, token],
    queryFn: () => dispatch(init(pid, token)),
    refetchOnWindowFocus: false,
  })
  const isObjectInvalidToBeShown = useIsObjectInvalidToBeShown()
  const selectedItemId = useSelectedItemId()

  if (!loading && isObjectInvalidToBeShown(project, error)) {
    return <InvalidObjectComponent object={project} error={error} readOnly={readOnly} />
  }

  const forwardProps = {
    items,
    project,
    selectedItemId,
    sortFn,
    loading,
    projectSelectorEnabled: false,
    readOnly,
    matrixPreviewMode,
  }

  switch (projectMode) {
    case PROJECT_MODE.CALENDAR:
      return <PMCalendarView {...forwardProps} />
    case PROJECT_MODE.REPORTS:
      forwardProps.items = projectItems
      return <PMProjectAnalyticsReport {...forwardProps} />
    case PROJECT_MODE.FEED:
      return <Feed {...forwardProps} />
    case PROJECT_MODE.GANTT:
      return <Gantt {...forwardProps} />
    case PROJECT_MODE.KANBAN:
      return <KanbanView {...forwardProps} />
    case PROJECT_MODE.LIST:
      return <MatrixTableView {...forwardProps} />
    default:
      return <ProjectMatrixView {...forwardProps} />
  }
}
