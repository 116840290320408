import _ from 'lodash'
import * as queryParamsHelper from '../helpers/queryParamsHelper'
import { useCallback, useMemo } from 'react'
import { app as microsoftTeams } from '@microsoft/teams-js'
import { getDeepLinkToOpenChatWithUsers } from '../utils/teams'
import { useGraphCollaboratorsMinusMe, useMe } from '../common/src/hooks/usersHooks'
import { itemHelper, projectHelper, userHelper } from '../common/src/helpers'
import { createMailToLinkForUsers } from '../common/src/helpers/mailHelper'
import { openExternalLink } from '../utils/externalLinkHandler'
import { useDispatch } from 'react-redux'
import { sendComment } from '../common/src/actions/commentsAPI'
import { ROUTE_ID } from '../routes/routeIdList'
import { useTeamsConfig } from '../queries/config'
import { getDeeplinkWithAppInfo } from '../common/src/helpers/teamsHelper'
import { useRouteId } from './useRouteId'

const optionMenuKeys = {
  mailTo: 'mailTo',
  openChat: 'openChat',
}

const isEmptyTrimming = _.flow([_.trim, _.isEmpty])

const getPartialMessageFor = object => {
  let type = null
  let name = null
  if (projectHelper.isProject(object)) {
    type = 'project'
    name = projectHelper.getName(object)
  } else if (itemHelper.isItem(object)) {
    type = 'item'
    name = itemHelper.getName(object)
  } else {
    return null
  }

  if (isEmptyTrimming(name)) {
    return '...'
  }
  return ` the ${type} '${name}'`
}
const getLink = (object, teamsConfig) => {
  if (projectHelper.isProject(object)) {
    const id = projectHelper.getIdd(object)
    return getDeeplinkWithAppInfo(teamsConfig).toProject(id)
  } else if (itemHelper.isItem(object)) {
    const id = itemHelper.getId(object)
    return getDeeplinkWithAppInfo(teamsConfig).toItem(id)
  } else {
    return null
  }
}

const getInitialMessageForOpeningChat = (object, teamsConfig) => {
  let partialMessage = getPartialMessageFor(object)
  if (!partialMessage) {
    return null
  }
  const link = getLink(object, teamsConfig)
  return `Let's talk about${partialMessage} (${link})`
}

export const useUsersMenuProps = ({ users, object, readOnly }) => {
  const dispatch = useDispatch()
  const routeId = useRouteId()
  const isEmbeddedOnTeams = queryParamsHelper.isEmbeddedOnTeams()
  const me = useMe()
  const usersMinusMe = useMemo(() => _.differenceBy(users, [me], userHelper.getEmail), [me, users])
  const graphCollaborators = useGraphCollaboratorsMinusMe()
  const graphCollaboratorsArray = useMemo(() => graphCollaborators.toArray(), [graphCollaborators])
  const usersInGraph = useMemo(() => {
    return _.intersectionBy(usersMinusMe, graphCollaboratorsArray, userHelper.getEmail)
  }, [usersMinusMe, graphCollaboratorsArray])
  const teamsConfig = useTeamsConfig()

  const mailTo = useCallback(() => {
    const link = createMailToLinkForUsers({ users: usersMinusMe })
    openExternalLink(link)
  }, [usersMinusMe])

  const openChat = useCallback(() => {
    const linkWithoutMessage = getDeepLinkToOpenChatWithUsers({ users: usersInGraph })
    if (itemHelper.isItem(object)) {
      const itemID = itemHelper.getId(object)
      const text = `Teams Chat: ${linkWithoutMessage}`
      dispatch(sendComment({ itemID, text: text }))
    }

    const message = getInitialMessageForOpeningChat(object, teamsConfig)
    const link = getDeepLinkToOpenChatWithUsers({ users: usersInGraph, message })
    microsoftTeams.openLink(link)
  }, [dispatch, object, usersInGraph, teamsConfig])

  const optionMenuItems = useMemo(() => {
    const items = []

    items.push({
      key: optionMenuKeys.mailTo,
      text: 'Mail to...',
      onClick: mailTo,
    })

    items.push({
      key: optionMenuKeys.openChat,
      text: 'Open Chat with...',
      onClick: openChat,
    })

    return items
  }, [mailTo, openChat])

  return useMemo(() => {
    const optionMenuKeysBlacklistSet = new Set()
    const isMeeting = routeId === ROUTE_ID.MEETING

    if (isMeeting || !isEmbeddedOnTeams || !usersMinusMe || usersMinusMe.length === 0) {
      optionMenuKeysBlacklistSet.add(optionMenuKeys.mailTo)
    }

    if (isMeeting || !isEmbeddedOnTeams || !usersInGraph || usersInGraph.length === 0) {
      optionMenuKeysBlacklistSet.add(optionMenuKeys.openChat)
    }

    const optionMenuKeysBlacklist = [...optionMenuKeysBlacklistSet]
    const items = _.differenceWith(optionMenuItems, optionMenuKeysBlacklist, (option, key) => option.key === key)
    if (items.length === 0) {
      return null
    }
    return { items }
  }, [optionMenuItems, isEmbeddedOnTeams, usersMinusMe, usersInGraph, routeId])
}
