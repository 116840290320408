import { memo, useCallback, useDeferredValue } from 'react'
import { useSelector } from 'react-redux'
import { RightDetailView } from '../rightDetailView/RightDetailView'
import { getRelativeURLKeepingQuerySearch } from '../../helpers/routeHelper'
import { MainLayout } from '../../components/layout/MainLayout'
import { ProjectLevelTopBar } from '../project/ProjectLevelTopBar'
import { itemHelper, stateHelper } from '../../common/src/helpers'
import { KanbanComponent } from './KanbanComponent'
import { uriDataCreator } from '../../common/src/helpers/URLHelper'
import { PM_API_RESOURCE_TYPE } from '../../common/src/constants'
import { linkSubject, SOURCES } from '../../reactions/linkSubject'
import { useTranslation } from 'react-i18next'

export const KanbanView = memo(
  ({
    project,
    items,
    selectedItemId,
    sortFn,
    loading,
    projectSelectorEnabled = true,
    readOnly = false,
    showOwner = true,
  }) => {
    const { t } = useTranslation()
    const selectedItemSync = useSelector(state => stateHelper.getItem(state, selectedItemId))
    const selectedItem = useDeferredValue(selectedItemSync)

    const onClickItem = useCallback(item => {
      const serverID = itemHelper.getId(item)
      const urlData = uriDataCreator(1, PM_API_RESOURCE_TYPE.ITEM, serverID)
      linkSubject.next({ urlData, source: SOURCES.KANBAN })
    }, [])

    const main = (
      <div className="flex h-full flex-col">
        <KanbanComponent
          project={project}
          items={items}
          selectedItem={selectedItem}
          selectedItemId={selectedItemId}
          onSelectItem={onClickItem}
          sortFn={sortFn}
          loading={loading}
          readOnly={readOnly}
          showOwner={showOwner}
        />
        <div className="flex justify-center px-2 py-1">{t('project_view.current_count', { count: items?.size })}</div>
      </div>
    )
    const detail = <RightDetailView readOnly={readOnly} />
    const topBar = (
      <ProjectLevelTopBar
        getNavigationURLForProjectId={getRelativeURLKeepingQuerySearch.listForProjectId}
        key={'ProjectMatrixViewTopBar'}
        project={project}
        projectSelectorEnabled={projectSelectorEnabled}
        readOnly={readOnly}
        items={items}
      />
    )
    return <MainLayout main={main} rightSide={detail} topBar={topBar} />
  }
)
