import { useDispatch } from 'react-redux'
import { useInfiniteQuery } from '@tanstack/react-query'
import { getSearchItem } from '@/common/src/actions/searchAPI'

const DEFAULT_LIMIT = 50

export const useSearchItems = (params: Record<string, any>) => {
  const dispatch = useDispatch()
  return useInfiniteQuery({
    queryKey: ['search', params],
    queryFn: async ({ pageParam, queryKey }) => {
      const params = queryKey[1] as Record<string, any>
      const finalParams = {
        ...params,
        offset: pageParam,
        limit: DEFAULT_LIMIT,
      }
      const fetchSearch = async (itemsFound = 0, offset = pageParam): Promise<any[]> => {
        const result = await dispatch(getSearchItem(finalParams))
        const hasNext = !!result?.payload?.meta?.next
        itemsFound += result?.payload?.objects?.length
        // If there is a next page and we didn't fill the limit yet, we need to download the next page
        if (hasNext && itemsFound < DEFAULT_LIMIT) {
          return [result, ...(await fetchSearch(itemsFound, offset + DEFAULT_LIMIT))]
        }
        return [result]
      }
      return fetchSearch()
    },
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      const lastSubPage = lastPage[lastPage.length - 1]
      return lastSubPage.payload?.meta?.next
        ? lastSubPage.payload.meta.offset + lastSubPage.payload.meta.limit
        : undefined
    },
    refetchOnWindowFocus: false,
  })
}
