import type { Project } from '@/types'
import { projectHelper } from '@/common/src/helpers'
import {
  adjustColorForBackground,
  bestTextColorForBackgroundColor,
  quadrantColorStringToArray,
} from '@/common/src/helpers/colorsHelpers'

interface ComputeQuadrantColorOptions {
  project: Project
  isDarkTheme: boolean
  quadrant: 0 | 1 | 2 | 3
}

interface Section {
  text: string
  background: string
}

interface QuadrantColors {
  main: Section
  header: Section
}

export const computeQuadrantColors = ({
  quadrant,
  project,
  isDarkTheme,
}: ComputeQuadrantColorOptions): QuadrantColors => {
  const colorString = projectHelper.getQuadrantColor(project, quadrant)
  const quadrantColorArray = quadrantColorStringToArray(colorString)
  const headerBackground = adjustColorForBackground(quadrantColorArray, 0, isDarkTheme)
  const mainBackground = adjustColorForBackground(quadrantColorArray, 20, isDarkTheme)
  const headerText = bestTextColorForBackgroundColor(headerBackground)
  const mainText = bestTextColorForBackgroundColor(mainBackground)
  return {
    header: {
      text: headerText,
      background: headerBackground,
    },
    main: {
      text: mainText,
      background: mainBackground,
    },
  }
}
