import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react'
import _ from 'lodash'
import styled, { useTheme } from 'styled-components'
import { cn } from '@appfluence/classnames'
import { TooltipHost } from '@/components/tooltip/TooltipHost'
import { DEFAULT_QUADRANTS_COLORS } from '../../../themes'
import { projectHelper } from '../../../common/src/helpers'
import { FlexColumn, FlexRow } from '../../layout/FlexContainer'
import { motion, useAnimationControls } from 'framer-motion'
import classes from './QuadrantSelector.module.css'

export const Container = styled(FlexColumn)`
  flex: 0;
  overflow: hidden;

  cursor: pointer;
  &.readOnly {
    cursor: inherit;
  }

  box-shadow: var(--shadow4);
`

const AnimatedContainer = motion(Container)

export const Row = FlexRow

export const Quadrant = motion.div

const ConditionalTooltip = ({ showTooltip, children, ...props }) =>
  showTooltip ? <TooltipHost {...props}>{children}</TooltipHost> : children

const quadrantNumbers = [0, 1, 2, 3]
const quadrantSizeStyle = (size, firstRowPercent, firstColumnPercent, q, color) => {
  const percent = [
    {
      height: firstRowPercent,
      width: firstColumnPercent,
    },
    {
      height: firstRowPercent,
      width: 1 - firstColumnPercent,
    },
    {
      height: 1 - firstRowPercent,
      width: firstColumnPercent,
    },
    {
      height: 1 - firstRowPercent,
      width: 1 - firstColumnPercent,
    },
  ]
  const styles = _.mapValues(percent[q], v => v * size)

  return { ...styles, backgroundColor: color }
}

const limitQuadrantCount = count => !!count && (count > 999 ? '∞' : count)

export const QuadrantSelector = memo(
  ({
    project,
    quadrantsColors,
    quadrant = -1,
    size,
    isItem = false,
    readOnly = true,
    onChanged,
    style,
    className,
    onClick,
    showTooltips = true,
    animate = false,
    counters = {},
    ...rest
  }) => {
    const theme = useTheme()
    const onChangedQuadrant = useCallback(
      q => () => {
        if (readOnly) {
          return
        }

        if (_.isFunction(onChanged)) {
          onChanged(q)
        }
      },
      [readOnly, onChanged]
    )

    const getTooltipForQuadrant = useCallback(
      (q = 0, selectedQuadrant = 0) => {
        if (!isItem) {
          return projectHelper.getSafeQuadrantName(project, q)
        }
        const friendlyQuadrant = q + 1
        if (q === selectedQuadrant) {
          return `This item is in quadrant ${friendlyQuadrant}`
        }
        if (!readOnly) {
          return `Move item to quadrant ${friendlyQuadrant}`
        }
        return ''
      },
      [isItem, project, readOnly]
    )

    const colors = useMemo(() => {
      if (project) {
        return quadrantNumbers.map(q => projectHelper.getQuadrantColorRGBA(project, q))
      }

      if (quadrantsColors) {
        return quadrantsColors
      }
      const defaultQuadrantColorMapper = quadrant => i =>
        quadrant === i ? theme.palette.themePrimary : DEFAULT_QUADRANTS_COLORS[i]
      return quadrantNumbers.map(defaultQuadrantColorMapper(quadrant))
    }, [project, quadrantsColors, quadrant, theme])

    let firstRowPercent = 0.5
    let firstColumnPercent = 0.5
    if (quadrant !== -1) {
      firstRowPercent = quadrant === 0 || quadrant === 1 ? 0.7 : 0.3
      firstColumnPercent = quadrant === 0 || quadrant === 2 ? 0.7 : 0.3
    }

    const quadrantStyleGenerator = _.partial(quadrantSizeStyle, size, firstRowPercent, firstColumnPercent)
    const quadrantStyles = quadrantNumbers.map(q => quadrantStyleGenerator(q, colors[q]))
    const containerStyle = { ...style, minWidth: size, minHeight: size }
    const quadrantTooltips = quadrantNumbers.map(q => getTooltipForQuadrant(q, quadrant))
    const containerClassname = cn('relative', className, { readOnly })

    const controls = useAnimationControls()
    const isMountedRef = useRef(true)

    useEffect(() => {
      isMountedRef.current = true
      return () => void (isMountedRef.current = false)
    }, [])

    useEffect(() => {
      const animateContainer = async () => {
        if (!isMountedRef.current) return
        await controls.start('scaledUp')
        if (!isMountedRef.current) return
        await controls.start('normal')
      }
      if (animate) {
        animateContainer()
      }
    }, [animate, controls, quadrant])

    return (
      <AnimatedContainer
        variants={{ scaledUp: { scale: 1.3 }, normal: { scale: 1 } }}
        initial={animate ? 'scaledUp' : undefined}
        animate={controls}
        transition={{ duration: 0.3 }}
        style={containerStyle}
        className={containerClassname}
        onClick={readOnly ? undefined : onClick}
        {...rest}
      >
        <Row>
          <ConditionalTooltip showTooltip={showTooltips} content={quadrantTooltips[0]}>
            <Quadrant
              className={classes.quadrant}
              layout="size"
              style={quadrantStyles[0]}
              onClick={onChangedQuadrant(0)}
            >
              {limitQuadrantCount(counters[0])}
            </Quadrant>
          </ConditionalTooltip>
          <ConditionalTooltip showTooltip={showTooltips} content={quadrantTooltips[1]}>
            <Quadrant
              className={classes.quadrant}
              layout="size"
              style={quadrantStyles[1]}
              onClick={onChangedQuadrant(1)}
            >
              {limitQuadrantCount(counters[1])}
            </Quadrant>
          </ConditionalTooltip>
        </Row>
        <Row>
          <ConditionalTooltip showTooltip={showTooltips} content={quadrantTooltips[2]}>
            <Quadrant
              className={classes.quadrant}
              layout="size"
              style={quadrantStyles[2]}
              onClick={onChangedQuadrant(2)}
            >
              {limitQuadrantCount(counters[2])}
            </Quadrant>
          </ConditionalTooltip>
          <ConditionalTooltip showTooltip={showTooltips} content={quadrantTooltips[3]}>
            <Quadrant
              className={classes.quadrant}
              layout="size"
              style={quadrantStyles[3]}
              onClick={onChangedQuadrant(3)}
            >
              {limitQuadrantCount(counters[3])}
            </Quadrant>
          </ConditionalTooltip>
        </Row>
        {!!counters.overdue && (
          <div className="absolute left-1/2 top-1/2 flex h-3.5 w-3.5 -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-red-900 text-[9px] text-red-50">
            {counters.overdue > 99 ? '∞' : counters.overdue}
          </div>
        )}
      </AnimatedContainer>
    )
  }
)
