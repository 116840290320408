import { ItemsFiltersView } from './ItemsFiltersView'
import { FILTER_REDUCER_KEYS } from '../../common/src/reducers/filtersKeys'
import { ITEMS_SORT_TYPE } from '../../common/src/actions/filtersActions'
import { Sections } from './ItemFiltersView.Common'

const sortTypeBlacklist = [ITEMS_SORT_TYPE.INDEX]

export const OneOnOneFiltersView = ({ ...rest }) => {
  return (
    <ItemsFiltersView
      filtersType={FILTER_REDUCER_KEYS.ONE_ON_ONE}
      sortTypeBlacklist={sortTypeBlacklist}
      showSelectColumnsButton
      hideSections={[Sections.Quadrant, Sections.StarredFirst]}
      {...rest}
    />
  )
}
