import styled from 'styled-components'

export const Ribbon = styled.span`
  font-size: 8px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 14px;
  transform: rotate(-45deg);
  width: 80px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#f70505 0%, #8f0808 100%);
  box-shadow: var(--shadow4);
  position: absolute;
  top: 12px;
  left: -21px;
  z-index: 2;
`
