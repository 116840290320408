import type { Comment } from '@/types/comment'
import DOMPurify from 'dompurify'
import type { PMChatMessageProps } from './PMChatMessage'
import { useTranslation } from 'react-i18next'
import { resourceURIParser } from '../../common/src/helpers/URLHelper'
import { useMe } from '../../common/src/hooks/usersHooks'
import { userHelper } from '../../common/src/helpers'
import { useCallback } from 'react'
import { useConfig } from '../../queries/config'

export const sanitize = (html: string) => {
  return DOMPurify.sanitize(html, {
    ALLOWED_URI_REGEXP:
      /^(?:(?:(?:f|ht)tps?|mailto|tel|callto|sms|cid|xmpp|prioritymatrix|pmatrix):|[^a-z]|[a-z+.-]+(?:[^a-z+.\-:]|$))/i,
  })
}

export const sanitizeReplyBox = (html: string) => {
  return DOMPurify.sanitize(html, {
    ALLOWED_TAGS: [],
  })
}

export const isAutomaticCommentFromAuthor = (comment: Comment) => {
  return comment.is_automatic && 'content_type' in comment.json && comment.json.content_type.startsWith('image/')
}

type DateTimeFormatOptions = Parameters<Date['toLocaleString']>[1]

const longFormat: DateTimeFormatOptions = {
  day: 'numeric',
  month: 'long',
  hour: 'numeric',
  minute: '2-digit',
}
const shortFormat: DateTimeFormatOptions = {
  day: 'numeric',
  month: 'long',
}

export function useTextCommentForJson(json?: Record<string, unknown>) {
  const { t } = useTranslation()
  const now = new Date()
  // In case date happens in the near future (within 3 days), show the hour too
  const farFuture = new Date(now.getTime() + 3 * 24 * 60 * 60 * 1000)
  farFuture.setHours(23, 59, 59, 999)

  if (json) {
    // check dueDate
    if (typeof json.dueDate === 'number') {
      const dueDate = new Date(json.dueDate * 1000)
      const shouldExpand = !json.allDay && dueDate <= farFuture
      const format = shouldExpand ? longFormat : shortFormat
      return t('item_chat.set_due_date', { date: dueDate.toLocaleString(undefined, format) })
    } else if (typeof json.reminderDate === 'number' && typeof json.user === 'string') {
      const reminderDate = new Date(json.reminderDate * 1000)
      const shouldExpand = reminderDate <= farFuture
      const format = shouldExpand ? longFormat : shortFormat
      return t('item_chat.reminder', { date: reminderDate.toLocaleString(undefined, format), user: json.user })
    }
  }
  return null
}

export const getImageUrl = (comment: PMChatMessageProps['comment']) => {
  const { json } = comment
  if (json && 'url' in json) {
    return json.url
  }
  const imageHtml = comment.text.split('<br/>')[0]
  return extractImageURL(imageHtml)
}

function extractImageURL(imageHtml: string) {
  const first = imageHtml.indexOf('"')
  if (first === -1 || first === imageHtml.length - 1) {
    return null
  }
  const second = imageHtml.indexOf('"', first + 1)
  if (second === -1 || second >= imageHtml.length) {
    return null
  }
  return imageHtml.substring(first + 1, second)
}

export function isCommentFromVisitor(
  comment: PMChatMessageProps['comment']
): comment is PMChatMessageProps['comment'] & { json: { visitor_email: string; visitor_name: string } } {
  return !!comment.json && 'visitor_email' in comment.json
}

export function isEditedComment(comment: PMChatMessageProps['comment']): comment is PMChatMessageProps['comment'] & {
  json: { previous_text: string; updated_at: string }
} {
  return !!comment.json && 'updated_at' in comment.json
}

export const useIsEditableComment = () => {
  const me = useMe()
  const editLimitMinutes = useConfig().data?.edit_comment_limit_minutes ?? 0

  return useCallback(
    (comment: Partial<Comment>, now: Date) => {
      const commentDate = new Date(`${comment.timestamp}Z`)
      return (
        !!comment.id &&
        !comment.is_automatic &&
        !comment.is_command &&
        !comment.is_read &&
        resourceURIParser(comment.author)?.id === userHelper.getID(me) &&
        now.getTime() - commentDate.getTime() < 1000 * 60 * editLimitMinutes
      )
    },
    [editLimitMinutes, me]
  )
}
